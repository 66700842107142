import ReactModal from "react-modal";
import Button from "components/common/buttons/Button";
import React from "react";
import useDelete from "modules/Admin/components/ScheduleAppointmentsTable/hooks/useDelete";

export default function DeleteModal({ isOpen, appointment, closeModal }) {
  const mutation = useDelete();

  const onSubmit = () => {
    mutation.mutate(appointment);
    closeModal();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Cancel?"
      style={{
        content: {
          width: 450,
          height: 150,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <div className="flex flex-col content-evenly gap-8">
        <div className="text-lg text-center">
          Are you sure you want to delete this Appointment?
        </div>
        <div className="flex gap-2">
          <Button
            className="w-full"
            onClick={() => {
              onSubmit();
            }}
          >
            Submit
          </Button>
          <Button
            className="bg-red-500 w-full"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ReactModal>
  );
}

import React, { useCallback } from "react";
import { useQueryClient } from "react-query";

export default function useAppointmentTimeModal() {
  const queryClient = useQueryClient();

  const [showAppointmentTimeModal, setShowAppointmentTimeModal] =
    React.useState(false);
  const [appointmentTimeModalAppointment, setAppointmentTimeModalAppointment] =
    React.useState(null);

  const openAppointmentTimeModal = useCallback((appointment) => {
    queryClient.invalidateQueries("modalAppointments");
    setAppointmentTimeModalAppointment(appointment);
    setShowAppointmentTimeModal(true);
  }, []);

  const closeAppointmentTimeModal = () => {
    setShowAppointmentTimeModal(false);
    setAppointmentTimeModalAppointment(null);
    queryClient.resetQueries("modalAppointments");
  };

  return {
    showAppointmentTimeModal,
    setShowAppointmentTimeModal,
    appointmentTimeModalAppointment,
    setAppointmentTimeModalAppointment,
    openAppointmentTimeModal,
    closeAppointmentTimeModal,
  };
}

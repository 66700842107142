import React, { useCallback } from "react";

export default function useDeleteModal() {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [deleteModalAppointment, setDeleteModalAppointment] =
    React.useState(null);

  const openDeleteModal = useCallback((appointment) => {
    setDeleteModalAppointment(appointment);
    setShowDeleteModal(true);
  }, []);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteModalAppointment(null);
  };

  return {
    showDeleteModal,
    setShowDeleteModal,
    deleteModalAppointment,
    setDeleteModalAppointment,
    openDeleteModal,
    closeDeleteModal,
  };
}

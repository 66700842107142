import { useQuery } from "react-query";
import { apiRequest } from "../../../services/api";
import { startOfDay, endOfDay, formatISO } from "date-fns";

export default function useGetAppointments() {
  const today = new Date();

  const start = formatISO(startOfDay(today));
  const end = formatISO(endOfDay(today));

  const getAppointments = () => {
    return apiRequest("get", `appointments?start=${start}&end=${end}`);
  };

  return useQuery("appointments", getAppointments, { refetchInterval: 10000 });
}

import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const host = window.location.host;

const environmentMap = {
  "localhost:3000": "local",
  "traffix-highland-stage.azurewebsites.net": "staging",
  "highland.traffix.com": "production",
  "traffix-highland.azurewebsites.net": "production",
};

if (environmentMap[host] === "staging") {
  LogRocket.init("q5pikg/traffix-highland-staging");

  Sentry.init({
    dsn: "https://abe00c5f97e04717b118f5a900fef678@o1150500.ingest.sentry.io/6223458",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
} else if (environmentMap[host] === "production") {
  LogRocket.init("q5pikg/traffix-highland");

  Sentry.init({
    dsn: "https://94a28e7ef3ff4918aba90731a9369d54@o1150500.ingest.sentry.io/6223473",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra("sessionURL", sessionURL);
  });
});

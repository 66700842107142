import { useMutation, useQueryClient } from "react-query";
import { apiRequestPromise } from "services/api";

export default function useEditLoads() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ appointment, timestamp, add, remove }) => {
      await apiRequestPromise("post", `edit-loads/`, {
        appointment,
        timestamp,
        add,
        remove,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("appointments");
      },
    }
  );
}

export default function AppointmentTableLayoutRow({ rows, row, isCheckedOut }) {
  const doorNum = row.original.door_num;
  const activeAppointmentsWithSameDoorNumber = rows
    ?.filter((otherRow) => !otherRow.original.checkout)
    .filter(
      (activeAppointment) => activeAppointment.original.door_num === doorNum
    );

  const isOnlyActiveAppointmentWithDoorNum = () => {
    return activeAppointmentsWithSameDoorNumber === 1;
  };

  const isEarliestOfActiveAppointmentsWithDoorNumber = () => {
    return (
      row.original.uuid ===
      activeAppointmentsWithSameDoorNumber[0].original.uuid
    );
  };

  const rowColor = (row) => {
    const isDoorNum = !!row.original.door_num;

    if (isCheckedOut) {
      return "bg-green-500";
    }

    if (isDoorNum) {
      if (
        isOnlyActiveAppointmentWithDoorNum() ||
        isEarliestOfActiveAppointmentsWithDoorNumber()
      ) {
        return "bg-red-500";
      } else {
        return "bg-purple-500";
      }
    }

    return "bg-yellow-300";
  };

  return (
    <div
      {...row.getRowProps()}
      className={`rounded p-2 ${rowColor(row)} flex-col bg-opacity-50`}
    >
      <div {...row.getRowProps()}>
        {row.cells.map((cell) => {
          return (
            <div {...cell.getCellProps()} className="w-full">
              {cell.render("Cell")}
            </div>
          );
        })}
      </div>
    </div>
  );
}

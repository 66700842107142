import { useState } from "react";
import Login from "../../pages/Login";
import React from "react";
import TextInput from "../../components/common/inputs/TextInput";
import { useFieldArray, useForm } from "react-hook-form";
import Button from "../../components/common/buttons/Button";
import Card from "../../components/common/containers/Card";
import CardLabel from "../../components/common/labels/CardLabel";
import CardError from "../../components/common/labels/CardError";
import RadioInput from "../../components/common/inputs/RadioInput";
import { apiRequest } from "../../services/api";
import AppointmentTime from "modules/Scheduler/components/AppointmentTime";
import useGetAppointmentOptions from "modules/Scheduler/hooks/useGetAppointmentOptions";
import { useQueryClient } from "react-query";
import useSchedulerStatus from "./hooks/useSchedulerStatus";
import Confirmation from "./components/Confirmation/Confirmation";
import LoadsList from "./components/LoadsList/LoadsList";
import Logo from "modules/common/Logo/Logo";
import useDate from "modules/Scheduler/hooks/useDate";
import DateSelection from "modules/Scheduler/components/DateSelection";
import { isDayOfPickup } from "utils/utils";

export default function Scheduler() {
  const queryClient = useQueryClient();

  const [page, setPage] = useState("scheduler");

  const { setStatus, submittedData, setSubmittedData, isAdding, isSubmitted } =
    useSchedulerStatus();

  const [formError, setFormError] = React.useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm();

  const watchLocation = watch("location");
  const watchDirection = watch("direction");
  const watchLoads = watch("loads");

  const { date, setDate } = useDate(watchDirection, watchLoads);

  const { data: appointmentOptions } = useGetAppointmentOptions(
    watchLoads,
    watchLocation,
    watchDirection,
    date
  );

  const {
    fields: loads,
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: "loads",
  });

  const onSubmit = (data) => {
    const submitData = {
      ...data,
      dockName: appointmentOptions.options.find(
        (option) => option.time === data.appointmentTime
      ).dock_name,
    };
    clearErrors("loads");
    setStatus("adding");
    apiRequest("post", "schedule", submitData)
      .then((res) => {
        setSubmittedData(res);
        queryClient.setQueryData("appointmentOptions", undefined);
        setStatus("submitted");
      })
      .catch(() => {
        setStatus("input");
        setFormError(true);
      });
  };

  if (page === "login") {
    return <Login setPage={setPage} />;
  }

  if (isSubmitted)
    return (
      <Confirmation
        setPage={setPage}
        getValues={getValues}
        reset={reset}
        setStatus={setStatus}
        submittedData={submittedData}
        setSubmittedData={setSubmittedData}
        setDate={setDate}
      />
    );

  if (page === "scheduler") {
    return (
      <>
        <div className="flex w-full items-center justify-between p-4">
          <div className="flex w-1/3 justify-start ">
            <div>
              <Logo />
            </div>
          </div>
          <div className="flex w-1/3 content-center justify-center text-center">
            <h1 className=" text-2xl font-semibold">Appointment Scheduler</h1>
          </div>
          <div className="flex w-1/3 justify-end">
            <Button
              onClick={() => {
                setPage("login");
              }}
              className="p-2"
            >
              Highland Login
            </Button>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col content-center justify-center py-8 lg:w-2/3 xl:w-[750px]">
            <form
              className="flex w-full flex-col gap-3 px-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="rounded bg-amber-200 p-4">
                Please schedule an appointment for a single truck pickup or
                delivery. If multiple POs are associated with a single truck
                pickup or delivery, please add all POs and select a single
                appointment. If you have multiple truck pickup appointments to
                schedule, please schedule one and repeat process
              </div>
              <Card>
                <CardLabel text="Broker/Carrier Name" />
                <TextInput
                  name="broker"
                  register={register}
                  rules={{ required: "Provide broker name" }}
                />
                <CardError error={errors?.broker?.message} />
              </Card>
              <Card>
                <CardLabel text="Name of Scheduler" />
                <TextInput
                  name="scheduler"
                  register={register}
                  rules={{ required: "Provide name of scheduler" }}
                />
                <CardError error={errors?.scheduler?.message} />
              </Card>
              <Card>
                <CardLabel text="Phone Number of Scheduler" />
                <TextInput
                  name="phone"
                  register={register}
                  rules={{ required: "Provide phone number of scheduler" }}
                />
                <CardError error={errors?.phone?.message} />
              </Card>
              <Card>
                <CardLabel text="Email Address" />
                <TextInput
                  name="email"
                  register={register}
                  rules={{
                    required: "Provide email address",
                    validate: (email) =>
                      isValidEmail(email) || "Provide a valid email address",
                  }}
                />
                <CardError error={errors?.email?.message} />
              </Card>
              <Card>
                <CardLabel text="Location" />
                <RadioInput
                  register={register}
                  name="location"
                  options={[
                    { value: "nb", label: "Northbrook" },
                    { value: "sc", label: "South Carolina" },
                  ]}
                  rules={{ required: "Choose location" }}
                />
                <CardError error={errors?.location?.message} />
              </Card>
              <Card>
                <CardLabel text="Direction" />
                <RadioInput
                  register={register}
                  name="direction"
                  options={[
                    { value: "pickup", label: "Pickup" },
                    { value: "delivery", label: "Delivery" },
                  ]}
                  rules={{ required: "Choose pickup or delivery" }}
                />
                <CardError error={errors?.direction?.message} />
              </Card>
              <Card>
                <LoadsList
                  loads={loads}
                  append={append}
                  remove={remove}
                  setError={setError}
                  clearErrors={clearErrors}
                  error={errors.loads?.message}
                  direction={watchDirection}
                  location={watchLocation}
                />
                <div className="mt-6 flex items-center gap-3">
                  <input
                    {...register("confirm", {
                      validate: {
                        atLeastOneLoad: () =>
                          getValues("loads").length > 0 ||
                          "You must add at least one load",
                        confirmed: (v) =>
                          !!v ||
                          "Confirm all added Loads/POs will be part of a single truck pickup or delivery",
                      },
                    })}
                    className="h-6 w-6 rounded text-amber-300"
                    type="checkbox"
                  />
                  <label htmlFor="confirm">
                    Confirm all added Loads/POs will be part of a single truck
                    pickup or delivery
                  </label>
                </div>
                <CardError error={errors?.confirm?.message} />
              </Card>
              <>
                <DateSelection
                  direction={watchDirection}
                  date={date}
                  setDate={setDate}
                  loads={watchLoads}
                />
                <AppointmentTime
                  appointmentOptions={appointmentOptions?.options}
                  register={register}
                />
                <Button
                  submitting={isAdding}
                  disabled={isAdding}
                  className="mt-4"
                >
                  SCHEDULE APPOINTMENT
                </Button>
                {formError && (
                  <p className="text-sm text-red-500">
                    Something went wrong. Please try again. If the problem
                    persists, speak to Highland personnel.
                  </p>
                )}
              </>
            </form>
          </div>
        </div>
      </>
    );
  }
}

// eslint-disable-next-line no-useless-escape
const isValidEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

import useUserData from "../components/auth/useUserData";
import Checkin from "modules/Checkin/Checkin";
import Checkout from "./Checkout";
import useChoosePage from "components/auth/useChoosePage";
import Scheduler from "modules/Scheduler/Scheduler";
import Admin from "modules/Admin";

export default function Home() {
  const { data: userData, isLoading } = useUserData();

  console.log(userData);

  const page = useChoosePage(userData);

  if (isLoading) {
    return "";
  }

  if (page === "scheduler") {
    return <Scheduler />;
  }

  if (page === "checkin") {
    return <Checkin location={userData?.location} />;
  }

  if (page === "checkout") {
    return <Checkout location={userData?.location} type={userData?.type} />;
  }

  if (page === "admin") {
    return <Admin location={userData?.location} />;
  }
}

import ReactModal from "react-modal";
import Card from "../common/containers/Card";
import CardLabel from "../common/labels/CardLabel";
import CardError from "../common/labels/CardError";
import TextInput from "../common/inputs/TextInput";
import { useForm } from "react-hook-form";
import Button from "../common/buttons/Button";
import React, { useState } from "react";
import useUpdateAppointment from "./hooks/useUpdateAppointment";
import { useQueryClient } from "react-query";

export default function DoorNumModal({
  isOpen,
  appointment,
  closeDoorNumModal,
}) {
  const queryClient = useQueryClient();

  const [adding, setAdding] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      doorNumber: appointment?.door_num ? appointment?.door_num : "",
    },
  });

  React.useEffect(() => {
    setValue("doorNumber", appointment?.door_num);
  }, [appointment, setValue]);

  const mutation = useUpdateAppointment();

  const onSubmit = (data) => {
    setAdding(true);
    mutation
      .mutateAsync({ ...appointment, door_num: data.doorNumber })
      .then(() => queryClient.invalidateQueries("appointments"))
      .then(() => {
        closeDoorNumModal();
      });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="List of loads"
      style={{
        content: {
          width: 400,
          height: 200,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      {/* <button onClick={closeDoorNumModal}>Close</button> */}
      <form
        className="w-full flex flex-col gap-3 px-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card>
          <CardLabel text="Door Number" />
          <TextInput
            name="doorNumber"
            register={register}
            rules={{ required: "Provide a door number" }}
            autofocus
          />
          <CardError error={errors?.name?.message} />
        </Card>
        <div className="flex gap-2">
          <Button className="w-full" submitting={adding}>
            Submit
          </Button>
          <Button
            className="bg-red-500 w-full"
            onClick={() => {
              closeDoorNumModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </ReactModal>
  );
}

import "./logging";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactModal from "react-modal";
import React from "react";
import Home from "./pages/Home";
import { ReactQueryDevtools } from "react-query/devtools";

ReactModal.setAppElement("#root");

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position={"top-right"} />
      <Home />
    </QueryClientProvider>
  );
}

export default App;

import { useMutation, useQueryClient } from "react-query";
import { apiRequest } from "services/api";

export default function useUpdateAppointmentTime() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const { uuid, appointmentTime, dockName } = data;
      return apiRequest("put", `update-appointment-time/${uuid}`, {
        appointmentTime,
        dockName,
      });
    },
    {
      onSuccess: () => {
        queryClient.removeQueries("modalOptions");
        queryClient.invalidateQueries("scheduledAppointments");
      },
    }
  );
}

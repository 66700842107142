import { useQuery, useMutation } from "react-query";
import { apiRequest } from "../../../services/api";

export default function useDoors() {
  const { data: doors } = useQuery("doors", () => {
    return apiRequest("get", "doors");
  });

  const nbShipping = doors?.find(
    (door) => door.location === "nb" && door.direction === "shipping"
  ).doors;
  const nbReceiving = doors?.find(
    (door) => door.location === "nb" && door.direction === "receiving"
  ).doors;
  const scShipping = doors?.find(
    (door) => door.location === "sc" && door.direction === "shipping"
  ).doors;
  const scReceiving = doors?.find(
    (door) => door.location === "sc" && door.direction === "receiving"
  ).doors;

  const { mutate: updateDoors } = useMutation((updatedDoors) => {
    return apiRequest("put", `doors`, updatedDoors);
  });

  return { nbShipping, nbReceiving, scShipping, scReceiving, updateDoors };
}

import Button from "../../../../components/common/buttons/Button";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import parseISO from "date-fns/parseISO";
import { useQueryClient } from "react-query";

export default function Confirmation({
  getValues,
  reset,
  setStatus,
  setPage,
  submittedData,
  setSubmittedData,
  setDate,
}) {
  const queryClient = useQueryClient();

  const handleReset = () => {
    reset({
      broker: getValues("broker"),
      scheduler: getValues("scheduler"),
      phone: getValues("phone"),
      email: getValues("email"),
    });
    setDate(undefined);
    setStatus("input");
    queryClient.setQueryData("appointmentOptions", undefined);
    setSubmittedData(null);
  };

  if (!submittedData) return "";

  return (
    <>
      <div className="w-full bg-slate-500 p-4 flex justify-between items-center">
        <h1 className="text-white text-2xl">Highland Appointment Scheduler</h1>
        <Button
          onClick={() => {
            setPage("login");
          }}
          className="p-2"
        >
          Highland Login
        </Button>
      </div>
      <div className="m-4 flex flex-col items-center justify-center gap-8">
        <div className="flex flex-col bg-slate-200 rounded p-4 text-center">
          <div className="text-xl">Reference Number</div>
          <div className="text-2xl">{submittedData.appointment_id_3g}</div>
        </div>
        <table>
          <thead>
            <th className="px-3 text-left">Load Number</th>
            <th className="px-3 text-left">PO/Order Number</th>
            <th className="px-3 text-left">Origin/Destination</th>
          </thead>
          <tbody>
            {submittedData.loads.map((load) => (
              <tr key={load.load_number}>
                <td className="px-3">{load.load_number}</td>
                <td className="px-3">
                  {load.po_number ||
                    load.order_number ||
                    "no po or order number"}
                </td>
                <td className="px-3">
                  {load.origin} to {load.destination}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="w-1/3">
          Your appointment has been scheduled for{" "}
          {formatDateTime(submittedData.appointment_time)}. Please save and
          provide the reference number above at the time of check-in.
        </div>
        <Button className="p-2" onClick={handleReset}>
          Schedule Another Single Truck Pickup or Delivery
        </Button>
      </div>
    </>
  );
}

function formatDateTime(dateTime) {
  return formatInTimeZone(parseISO(dateTime), "UTC", "M/d/yyyy h:mm a");
}

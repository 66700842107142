import ReactModal from "react-modal";
import Button from "components/common/buttons/Button";
import React, { useState, useEffect } from "react";
import useUpdateAppointmentTime from "modules/Admin/components/ScheduleAppointmentsTable/hooks/useUpdateAppointmentTime";
import useGetAppointmentOptions from "modules/Admin/components/ScheduleAppointmentsTable/components/AppointmentModal/hooks/useGetAppointmentOptions";
import AppointmentTime from "modules/Admin/components/ScheduleAppointmentsTable/components/AppointmentModal/components/AppointmentTime";
import { useForm } from "react-hook-form";
import Card from "components/common/containers/Card";
import CardLabel from "components/common/labels/CardLabel";
import { useQueryClient } from "react-query";
import DatePicker from "modules/Admin/components/ScheduleAppointmentsTable/components/AppointmentModal/components/DatePicker";
import { utcToSameLocalTime } from "modules/common/dateUtilities";
import { apiRequest } from "../../../../../../services/api";

export default function AppointmentTimeModal({
  isOpen,
  appointment,
  closeModal,
}) {
  const queryClient = useQueryClient();
  const mutation = useUpdateAppointmentTime();

  const [selectedDate, setSelectedDate] = useState();
  const [earliestDate, setEarliestDate] = useState();
  const [latestDate, setLatestDate] = useState();

  console.log(selectedDate);

  useEffect(() => {
    const { loads, direction } = appointment;

    async function setEarliestAndLatestDates() {
      const loadNumbers = loads.map((load) => load.load_number);

      const { earliest, latest } = await apiRequest(
        "get",
        `earliest-and-latest-dates?loadNumbers=${loadNumbers}&direction=${direction}`
      );

      setEarliestDate(utcToSameLocalTime(earliest));
      setLatestDate(utcToSameLocalTime(latest));

      if (
        selectedDate < utcToSameLocalTime(earliest) ||
        selectedDate > utcToSameLocalTime(latest) ||
        utcToSameLocalTime(latest) < new Date()
      ) {
        setSelectedDate(null);
      } else {
        setSelectedDate(utcToSameLocalTime(appointment.appointment_time));
      }
    }
    setEarliestAndLatestDates();
  }, [appointment, earliestDate, latestDate, selectedDate]);

  const { register, handleSubmit } = useForm();

  const {
    data: appointmentOptions,
    isError,
    error,
    isLoading,
  } = useGetAppointmentOptions(
    appointment.loads[0].load_number || null,
    appointment.location,
    appointment.direction,
    selectedDate
  );

  const onSubmit = (data) => {
    const submitData = {
      ...data,
      dockName: appointmentOptions.options.find(
        (option) => option.time === data.appointmentTime
      ).dock_name,
    };
    mutation.mutate({ uuid: appointment.uuid, ...submitData });
    closeModal();
  };

  if (isError) {
    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel="Cancel?"
        style={{
          content: {
            width: 500,
            height: 800,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div className="flex flex-col content-evenly gap-8">
          <div className="text-center text-lg">{error.response?.data}</div>
          <div className="flex gap-2">
            <Button
              type="button"
              className="w-full bg-red-500"
              onClick={() => {
                queryClient.removeQueries("modalOptions");
                closeModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ReactModal>
    );
  }

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Cancel?"
      style={{
        content: {
          width: 500,
          height: 875,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      {!earliestDate || !latestDate || selectedDate === undefined ? (
        <Card>Loading...</Card>
      ) : (
        <div className="flex flex-col content-evenly gap-6">
          <form
            className="flex flex-col content-evenly gap-8 text-center text-lg"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Card>
              <CardLabel text="Date" />
              <DatePicker
                appointmentDate={selectedDate}
                direction={appointment.direction}
                setSelectedDate={setSelectedDate}
                earliest={earliestDate}
                latest={latestDate}
              />
            </Card>
            {!isLoading && (
              <AppointmentTime
                appointmentOptions={appointmentOptions?.options}
                register={register}
              />
            )}
            <div className="flex gap-2">
              <Button className="w-full">Submit</Button>
              <Button
                type="button"
                className="w-full bg-red-500"
                onClick={() => {
                  queryClient.removeQueries("modalOptions");
                  closeModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      )}
    </ReactModal>
  );
}

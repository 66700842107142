import propTypes from "prop-types";
import Spinner from "./Spinner";

export default function Button({
  className,
  onClick,
  children,
  submitting,
  disabled,
}) {
  return (
    <button
      className={`flex justify-center items-center bg-amber-300 rounded h-10 uppercase text-lg font-medium ${className}`}
      onClick={onClick}
      disabled={submitting || disabled}
    >
      {submitting ? <Spinner /> : children}
    </button>
  );
}

Button.propTypes = {
  label: propTypes.string,
};

import { zonedTimeToUtc } from "date-fns-tz";

export default class LoadValidator {
  constructor(
    loads,
    load_num,
    origin,
    destination,
    earliest,
    latest,
    appointment,
    po_number,
    order_number,
    direction
  ) {
    this.loads = loads;
    this.loadNumber = load_num;
    this.origin = origin;
    this.destination = destination;
    this.earliest = earliest;
    this.latest = latest;
    this.appointment = appointment;
    this.po_number = po_number;
    this.order_number = order_number;
    this.direction = direction;
  }

  validate() {
    this.validateDateInFuture();

    if (this.loads.length === 0) {
      return;
    }

    this.validateLocation();
    this.validateUniqueness();
    this.validateSameDate();
  }

  validateDateInFuture() {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = new Date();
    const dateOfNewLoad = zonedTimeToUtc(this.earliest.slice(0, 10), tz);

    if (now < dateOfNewLoad && this.direction === "pickup") {
      throw Error("Pickup date is in the future");
    }
  }

  validateLocation() {
    if (
      this.direction === "pickup" &&
      this.loads[(0)["origin"] !== this.origin]
    ) {
      throw Error("Location does not match");
    }

    if (
      this.direction === "delivery" &&
      this.loads[0].destination !== this.destination
    ) {
      throw Error("Location does not match");
    }
  }

  validateUniqueness() {
    if (!this.loads.every((load) => load.load !== this.loadNumber)) {
      throw Error("Load has already been added");
    }
  }

  validateSameDate() {
    const dateOfExistingLoads = new Date(this.loads[0].earliest).toDateString();
    const dateOfNewLoad = new Date(this.earliest).toDateString();
    if (dateOfExistingLoads !== dateOfNewLoad) {
      throw Error("Loads do not have the same date");
    }
  }
}

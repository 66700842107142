import { useMutation, useQueryClient } from "react-query";
import { apiRequestPromise } from "../../../services/api";

export default function useCheckout() {
  const queryClient = useQueryClient();

  return useMutation(
    (checkoutData) =>
      apiRequestPromise("patch", `checkout/${checkoutData.uuid}`, checkoutData),
    {
      onSettled: () => {
        queryClient.invalidateQueries("appointments");
      },
    }
  );
}

import AppointmentTableLayoutRow from "./AppointmentTableLayoutRow";

export default function AppointmentTableLayoutCheckedOutRows({
  rows,
  prepareRow,
}) {
  return (
    <div className="flex flex-col gap-4">
      {rows
        .filter((row) => !!row.original.checkout)
        .map((row) => {
          prepareRow(row);
          return (
            <AppointmentTableLayoutRow
              key={row.original.uuid}
              row={row}
              isCheckedOut={true}
            />
          );
        })}
    </div>
  );
}

import React from "react";
import AppointmentTable from "../components/Checkout/AppointmentTable";
import useGetAppointments from "../components/Checkout/hooks/useGetAppointments";

export default function Checkout({ location, type }) {
  const { data: appointments, isLoading, isError } = useGetAppointments();

  console.log(appointments?.map((a) => a.location));

  const [tableData, setTableData] = React.useState(null);

  const getHeading = () => {
    if (location === "nb") {
      if (type === "shipping") {
        return "Highland Northbrook Shipping";
      } else if (type === "receiving") {
        return "Highland Northbrook Receiving";
      }
    } else if (location === "sc") {
      if (type === "shipping") {
        return "Highland SC Shipping";
      } else if (type === "receiving") {
        return "Highland SC Receiving";
      }
    }
  };

  React.useEffect(() => {
    setTableData(appointments);
  }, [appointments]);

  if (isError) {
    return "Error!";
  }

  if (isLoading || !tableData) {
    return "Loading!";
  }

  return (
    <AppointmentTable
      tableData={tableData}
      heading={getHeading()}
      location={location}
    />
  );
}

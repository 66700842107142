import { useState } from "react";

export default function useSchedulerStatus() {
  const [status, setStatus] = useState("input");
  const [submittedData, setSubmittedData] = useState(null);

  const isAdding = status === "adding";
  const isSubmitted = status === "submitted";

  return {
    setStatus,
    submittedData,
    setSubmittedData,
    isAdding,
    isSubmitted,
  };
}

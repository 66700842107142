import React from "react";
import TextInput from "components/common/inputs/TextInput";
import { useFieldArray, useForm } from "react-hook-form";
import Button from "components/common/buttons/Button";
import LoadsList from "modules/Checkin/components/LoadsList";
import Card from "components/common/containers/Card";
import CardLabel from "components/common/labels/CardLabel";
import CardError from "components/common/labels/CardError";
import RadioInput from "components/common/inputs/RadioInput";
import { apiRequest } from "services/api";
import useCheckinStatus from "components/Checkin/hooks/useCheckinStatus";
import { useQueryClient } from "react-query";
import format from "date-fns/format";
import Logo from "modules/common/Logo/Logo";

export default function Checkin({ location }) {
  const { setStatus, isAdding, isSubmitted } = useCheckinStatus();

  const [formError, setFormError] = React.useState(false);

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const watchDirection = watch("direction");

  const {
    fields: loads,
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: "loads",
  });

  const getHeading = () => {
    if (location === "nb") {
      return "Highland Northbrook";
    } else if (location === "sc") {
      return "Highland SC";
    }
  };

  const buildFormData = (data) => {
    data.timestamp = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
    data.loads = JSON.stringify(data.loads);
    return data;
  };

  const onSubmit = (data) => {
    clearErrors("loads");
    setStatus("adding");
    apiRequest("post", "checkin", buildFormData(data))
      .then(() => {
        setStatus("submitted");
      })
      .catch((error) => {
        setStatus("input");
        setFormError(true);
      });
  };

  if (isSubmitted) {
    return (
      <div className="flex h-screen flex-col items-center justify-center gap-4">
        <h1 className="text-lg font-medium">Confirmed</h1>
        <p>Wait for guidance from Highland personnel.</p>
        <Button onClick={() => window.location.reload()} className="p-2">
          Finish
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full items-center justify-between p-4">
        <div className="flex w-1/3 justify-start ">
          <div>
            <Logo />
          </div>
        </div>
        <div className="flex w-1/3 content-center justify-center text-center">
          <h1 className=" text-2xl font-semibold">{getHeading()}</h1>
        </div>
        <div className="flex w-1/3 justify-end">
          <Button
            onClick={() => {
              localStorage.clear();
              queryClient.invalidateQueries("userData");
            }}
            className="p-2"
          >
            Logout
          </Button>
        </div>
      </div>
      <div className="mx-auto flex flex-col content-center justify-center py-8 lg:w-2/3 xl:w-[750px]">
        <form
          className="flex w-full flex-col gap-3 px-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Card>
            <CardLabel text="Driver Name" />
            <TextInput
              name="name"
              register={register}
              rules={{ required: "Provide your name" }}
            />
            <CardError error={errors?.name?.message} />
          </Card>
          <Card>
            <CardLabel text="Carrier Name" />
            <TextInput
              name="carrier"
              register={register}
              rules={{ required: "Provide the name of your Carrier" }}
            />
            <CardError error={errors?.carrier?.message} />
          </Card>
          <Card>
            <CardLabel text="Trailer Number" />
            <TextInput
              name="trailer"
              register={register}
              rules={{ required: "Provide your Trailer Number" }}
            />
            <CardError error={errors?.trailer?.message} />
          </Card>
          <Card>
            <CardLabel text="Direction" />
            <RadioInput
              register={register}
              name="direction"
              options={[
                { value: "pickup", label: "Pickup" },
                { value: "delivery", label: "Delivery" },
              ]}
              rules={{ required: "Choose pickup or delivery" }}
            />
            <CardError error={errors?.direction?.message} />
          </Card>
          <Card>
            <LoadsList
              loads={loads}
              append={append}
              remove={remove}
              setError={setError}
              clearErrors={clearErrors}
              error={errors.loads?.message}
              direction={watchDirection}
              location={location}
            />
            <div className="mt-6 flex items-center gap-3">
              <input
                {...register("confirm", {
                  validate: {
                    atLeastOneLoad: () =>
                      getValues("loads").length > 0 ||
                      "You must add at least one load",
                    confirmed: (v) =>
                      !!v ||
                      "Confirm that all of the loads that being picked up or delivered have been added",
                  },
                })}
                className="h-6 w-6 rounded text-amber-300"
                type="checkbox"
              />
              <label htmlFor="confirm">
                Confirm that all of the loads that being picked up or delivered
                have been added.
              </label>
            </div>
            <CardError error={errors?.confirm?.message} />
          </Card>
          <Card>
            <CardLabel text="Driver's License" />
            <div className="mt-6 flex items-center gap-3">
              <input
                {...register("license", {
                  validate: {
                    license: (v) =>
                      !!v ||
                      "Confirm that you have verified the driver's license",
                  },
                })}
                className="h-6 w-6 rounded text-amber-300"
                type="checkbox"
              />
              <label htmlFor="licence">
                Confirm that you have verified the driver's license.
              </label>
            </div>
            <CardError error={errors?.license?.message} />
          </Card>
          <Button submitting={isAdding} className="mt-4">
            CHECK IN
          </Button>
          {formError && (
            <p className="text-sm text-red-500">
              Something went wrong. Please try again. If the problem persists,
              speak to Highland personnel.
            </p>
          )}
        </form>
      </div>
    </>
  );
}

import axios from "axios";

const environmentMap = {
  "localhost:3000": {
    base_url: "http://localhost:3000",
    api_base_url: "http://localhost:8000",
  },
  "traffix-highland-stage.azurewebsites.net": {
    base_url: "https://traffix-highland-stage.azurewebsites.net",
    api_base_url: "https://traffix-highland-api-stage.azurewebsites.net",
  },
  "highland.traffix.com": {
    base_url: "http://highland.traffix.com",
    api_base_url: "https://traffix-highlandapi2.azurewebsites.net",
  },
  "traffix-highland.azurewebsites.net": {
    base_url: "https://traffix-highland.azurewebsites.net",
    api_base_url: "https://traffix-highlandapi2.azurewebsites.net",
  },
};

const environment = window.location.host;

const base_url = environmentMap[environment]["base_url"];
const api_base_url = environmentMap[environment]["api_base_url"];

export function loginRequest(username, password) {
  return axios
    .post(`${api_base_url}/auth`, { username, password })
    .then(({ data }) => {
      localStorage.setItem("token", data.token);
      localStorage.setItem("userId", data.user_id);
      localStorage.setItem("username", data.username);
      localStorage.setItem("location", data.location);
      localStorage.setItem("type", data.type);
      window.location.replace(`${base_url}`);
    })
    .catch((error) => Promise.reject(error));
}

function getHeaders(type) {
  const token = localStorage.getItem("token");
  if (type === "multipart") {
    return {
      Authorization: `Token ${token}`,
      "content-type": "multipart/form-data",
    };
  }

  if (!token) return {};

  return {
    Authorization: `Token ${token}`,
  };
}

export function apiRequest(method, url, data, type) {
  return axios({
    method,
    url: `${api_base_url}/${url}`,
    data,
    headers: getHeaders(type),
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) {
        // localStorage.clear();
        // window.location.replace(`${base_url}/login`);
      } else {
        return Promise.reject(error);
      }
    });
}

export async function apiRequestPromise(method, url, data, type) {
  return await axios({
    method,
    url: `${api_base_url}/${url}`,
    data,
    headers: getHeaders(type),
  });
}

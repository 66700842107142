import RadioInput from "components/common/inputs/RadioInput";
import Card from "components/common/containers/Card";
import CardLabel from "components/common/labels/CardLabel";

export default function AppointmentTime({ appointmentOptions, register }) {
  if (!appointmentOptions) {
    return (
      <div className="bg-slate-200 rounded p-4 flex opacity-40">
        <div className="flex flex-col w-full ">
          <CardLabel text="Appointment Options" />
        </div>
      </div>
    );
  }

  if (appointmentOptions.length === 0) {
    return (
      <Card>
        <CardLabel text="Appointment Options" />
        <p className="p-1 opacity-40">No appointment options</p>
      </Card>
    );
  }

  return (
    <Card>
      <CardLabel text="Appointment Options" />
      <RadioInput
        register={register}
        name="appointmentTime"
        options={appointmentOptions.map((option) => {
          return { value: option.time, label: formatTime(option.time) };
        })}
      />
    </Card>
  );
}

function formatTime(time) {
  return new Date(time + "Z").toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
}

import { utcToSameLocalTime } from "modules/common/dateUtilities";

export default class LoadValidator {
  constructor(
    loads,
    load_num,
    origin,
    destination,
    earliest,
    latest,
    appointment,
    po_number,
    order_number,
    direction
  ) {
    this.loads = loads;
    this.loadNumber = load_num;
    this.origin = origin;
    this.destination = destination;
    this.earliest = earliest;
    this.latest = latest;
    this.appointment = appointment;
    this.po_number = po_number;
    this.order_number = order_number;
    this.direction = direction;
  }

  validate() {
    if (this.direction === "pickup") {
      this.validatePickup();
    } else if (this.direction === "delivery") {
      this.validateDelivery();
    }
  }

  validatePickup() {
    if (this.loads.length > 0) {
      this.validateLocation();
      this.validateUniqueness();
    }
    // if there is an appointment, means that we're validating in the context of updating an appointment
    if (this.appointment) {
      this.validateAppointmentDateIsWithinWindow();
    } else {
      this.validateWindowsOverlap();
    }
    this.validateWindowIsInFuture();
  }

  validateDelivery() {
    if (this.loads.length > 0) {
      this.validateLocation();
      this.validateUniqueness();
    }
  }

  validateWindowIsInFuture() {
    const now = new Date();
    const tomorrow = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );
    if (utcToSameLocalTime(this.latest) < tomorrow) {
      throw Error(
        "Pickup window is not in the future. Note that Highland does not support same-day pickups."
      );
    }
  }

  validateAppointmentDateIsWithinWindow() {
    const appointmentDate = utcToSameLocalTime(
      this.appointment.appointment_time
    );
    const earliestDate = utcToSameLocalTime(this.earliest);
    const latestDate = utcToSameLocalTime(this.latest);
    if (appointmentDate < earliestDate || appointmentDate > latestDate) {
      throw Error("Appointment date is not within new load's window");
    }
  }

  // checking to see if the window of load has any overlap with windows of other loads
  validateWindowsOverlap() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startOfExistingLoadsSharedWindow =
      this.getStartOfExistingLoadsSharedWindow();
    const endOfExistingLoadsSharedWindow =
      this.getEndOfExistingLoadsSharedWindow();
    if (
      utcToSameLocalTime(this.latest) < startOfExistingLoadsSharedWindow ||
      utcToSameLocalTime(this.earliest) > endOfExistingLoadsSharedWindow
    ) {
      throw Error("Pickup window does not overlap with other Loads");
    }
  }

  getStartOfExistingLoadsSharedWindow() {
    let latestEarliestDate;
    this.loads.forEach((load) => {
      const loadEarliestDate = utcToSameLocalTime(load.earliest);
      if (!latestEarliestDate || loadEarliestDate > latestEarliestDate) {
        latestEarliestDate = loadEarliestDate;
      }
    });

    return latestEarliestDate;
  }

  getEndOfExistingLoadsSharedWindow() {
    let earliestLatestDate;
    this.loads.forEach((load) => {
      const loadLatestDate = utcToSameLocalTime(load.latest);
      if (!earliestLatestDate || loadLatestDate < earliestLatestDate) {
        earliestLatestDate = loadLatestDate;
      }
    });
    return earliestLatestDate;
  }

  validateLocation() {
    if (
      this.direction === "pickup" &&
      this.loads[0]["origin"] !== this.origin
    ) {
      throw Error("Location does not match");
    }

    if (
      this.direction === "delivery" &&
      this.loads[0].destination !== this.destination
    ) {
      throw Error("Location does not match");
    }
  }

  validateUniqueness() {
    if (!this.loads.every((load) => load.load !== this.loadNumber)) {
      throw Error("Load has already been added");
    }
  }
}

import { useMutation } from "react-query";
import { apiRequest } from "../../../services/api";

export default function useUpdateAppointment(closeModal) {
  return useMutation((updatedAppointment) =>
    apiRequest(
      "patch",
      `appointments/${updatedAppointment.uuid}`,
      updatedAppointment
    )
  );
}

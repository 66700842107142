import ReactModal from "react-modal";
import Card from "../common/containers/Card";
import CardLabel from "../common/labels/CardLabel";
import CardError from "../common/labels/CardError";
import TextInput from "../common/inputs/TextInput";
import { useForm } from "react-hook-form";
import Button from "../common/buttons/Button";
import React, { useState } from "react";
import useUpdateAppointment from "./hooks/useUpdateAppointment";
import { useQueryClient } from "react-query";

export default function EditInfoModal({
  isOpen,
  appointment,
  field,
  closeEditInfoModal,
}) {
  const queryClient = useQueryClient();

  const [adding, setAdding] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      input: appointment[field.field] ? appointment[field.field] : "",
    },
  });

  React.useEffect(() => {
    setValue("input", appointment[field.field]);
  }, [appointment, setValue, field.field]);

  const mutation = useUpdateAppointment(closeEditInfoModal);

  const onSubmit = (data) => {
    const newAppointment = { ...appointment };
    newAppointment[field.field] = data.input;
    setAdding(true);
    mutation
      .mutateAsync(newAppointment)
      .then(() => queryClient.invalidateQueries("appointments"))
      .then(() => {
        closeEditInfoModal();
      });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="List of loads"
      style={{
        content: {
          width: 400,
          height: 200,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <form
        className="w-full flex flex-col gap-3 px-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card>
          <CardLabel text={field.fieldName} />
          <TextInput name="input" register={register} autofocus />
          <CardError error={errors?.name?.message} />
        </Card>
        <div className="flex gap-2">
          <Button className="w-full" submitting={adding}>
            Submit
          </Button>
          <Button
            className="bg-red-500 w-full"
            onClick={() => {
              closeEditInfoModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </ReactModal>
  );
}

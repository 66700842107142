import React, { useCallback } from "react";

export default function useCheckoutModal() {
  const [showCheckoutModal, setShowCheckoutModal] = React.useState(false);
  const [checkoutModalAppointment, setCheckoutModalAppointment] =
    React.useState(null);

  const openCheckoutModal = useCallback((appointment) => {
    setCheckoutModalAppointment(appointment);
    setShowCheckoutModal(true);
  }, []);

  const closeCheckoutModal = () => {
    setShowCheckoutModal(false);
    setCheckoutModalAppointment(null);
  };

  return {
    showCheckoutModal,
    setShowCheckoutModal,
    checkoutModalAppointment,
    setCheckoutModalAppointment,
    openCheckoutModal,
    closeCheckoutModal,
  };
}

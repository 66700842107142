import propTypes from "prop-types";

export default function RadioInput({
  register,
  name,
  label,
  options,
  rules,
  error,
}) {
  return (
    <div className="flex flex-col gap-2">
      <label className="p-1">{label}</label>
      <div className="grid grid-cols-2 gap-1">
        {options.map((option, index) => {
          return (
            <div key={index} className="flex content-center">
              <input
                {...register(name, rules)}
                type="radio"
                value={option.value}
                className="self-center mr-2 h-6 w-6 text-amber-300"
              />
              <label key={index} htmlFor={name}>
                {option.label}
              </label>
            </div>
          );
        })}
      </div>

      {error ? <p>error</p> : ""}
    </div>
  );
}

RadioInput.propTypes = {
  register: propTypes.func,
  name: propTypes.string.isRequired,
  label: propTypes.string,
  options: propTypes.array,
  rules: propTypes.object,
  error: propTypes.string,
};

import React, { useCallback } from "react";

export default function useEditLoadsModal() {
  const [showEditLoadsModal, setShowEditLoadsModal] = React.useState(false);
  const [editLoadsModalAppointment, setEditLoadsModalAppointment] =
    React.useState(null);

  const openEditLoadsModal = useCallback((appointment) => {
    setEditLoadsModalAppointment(appointment);
    setShowEditLoadsModal(true);
  }, []);

  const closeEditLoadsModal = () => {
    setShowEditLoadsModal(false);
    setEditLoadsModalAppointment(null);
  };

  return {
    showEditLoadsModal,
    setShowEditLoadsModal,
    editLoadsModalAppointment,
    setEditLoadsModalAppointment,
    openEditLoadsModal,
    closeEditLoadsModal,
  };
}

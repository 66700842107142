import propTypes from "prop-types";

export default function AddButton({ onClick, isAdding }) {
  if (isAdding) {
    return (
      <div className="flex  w-full justify-center p-1 px-2 bg-green-500 rounded ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 animate-spin"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
      </div>
    );
  }

  return (
    <button
      className="flex  w-full justify-center p-1 px-2 bg-green-500 rounded "
      onClick={onClick}
    >
      VALIDATE & ADD LOAD
    </button>
  );
}

AddButton.propTypes = {
  onClick: propTypes.func.isRequired,
  isAdding: propTypes.bool,
};

import ReactModal from "react-modal";
import Card from "../common/containers/Card";
import Button from "../common/buttons/Button";
import React from "react";
import { useState } from "react";
import EditLoadsModalAdder from "./EditLoadsModalAdder";
import EditLoadsModalLoads from "./EditLoadsModalLoads";
import useEditLoads from "./hooks/useEditLoads";
import { format } from "date-fns";

export default function EditLoadsModal({
  isOpen,
  appointment,
  closeEditLoadsModal,
  location,
}) {
  const [adding, setAdding] = useState(false);

  const [loads, setLoads] = useState(appointment.loads);

  const [remove, setRemove] = useState([]);
  const [add, setAdd] = useState([]);

  const mutation = useEditLoads();

  const onSubmit = () => {
    setAdding(true);
    mutation
      .mutateAsync({
        appointment: appointment.uuid,
        timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        add,
        remove,
      })
      .then(() => {
        closeEditLoadsModal();
      });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="List of loads"
      style={{
        content: {
          width: 750,
          height: loads.length * 24 + 260,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <Card>
        <EditLoadsModalLoads
          loads={loads}
          setLoads={setLoads}
          setRemove={setRemove}
          remove={remove}
          direction={appointment.direction}
        />
        <EditLoadsModalAdder
          loads={loads}
          setLoads={setLoads}
          add={add}
          setAdd={setAdd}
          appointment={appointment}
          location={location}
        />
      </Card>
      <div className="flex gap-2 mt-2">
        <Button className="w-full" onClick={onSubmit} submitting={adding}>
          Submit
        </Button>
        <Button
          className="bg-red-500 w-full"
          onClick={() => {
            closeEditLoadsModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </ReactModal>
  );
}

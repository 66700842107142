import propTypes from "prop-types";

export default function TextInput({
  register,
  name,
  type,
  label,
  placeholder,
  rules,
  autofocus,
}) {
  return (
    <div className="flex flex-col">
      <label className="p-1">{label}</label>
      <input
        {...register(name, rules)}
        placeholder={placeholder}
        type={type}
        className="p-1 rounded"
        autoFocus={autofocus}
      />
    </div>
  );
}

TextInput.propTypes = {
  register: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  type: propTypes.string,
  label: propTypes.string,
  placeholder: propTypes.string,
  rules: propTypes.object,
  error: propTypes.string,
};

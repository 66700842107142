import React, { useCallback } from "react";

export default function useDoorNumModal() {
  const [showDoorNumModal, setShowDoorNumModal] = React.useState(false);
  const [doorNumModalAppointment, setDoorNumModalAppointment] =
    React.useState(null);

  const openDoorNumModal = useCallback((appointment) => {
    setDoorNumModalAppointment(appointment);
    setShowDoorNumModal(true);
  }, []);

  const closeDoorNumModal = () => {
    setShowDoorNumModal(false);
    setDoorNumModalAppointment(null);
  };

  return {
    showDoorNumModal,
    setShowDoorNumModal,
    doorNumModalAppointment,
    setDoorNumModalAppointment,
    openDoorNumModal,
    closeDoorNumModal,
  };
}

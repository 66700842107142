import React, { useCallback } from "react";

export default function useCancelModal() {
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [cancelModalAppointment, setCancelModalAppointment] =
    React.useState(null);

  const openCancelModal = useCallback((appointment) => {
    setCancelModalAppointment(appointment);
    setShowCancelModal(true);
  }, []);

  const closeCancelModal = () => {
    setShowCancelModal(false);
    setCancelModalAppointment(null);
  };

  return {
    showCancelModal,
    setShowCancelModal,
    cancelModalAppointment,
    setCancelModalAppointment,
    openCancelModal,
    closeCancelModal,
  };
}

import { useMutation, useQueryClient } from "react-query";
import { apiRequestPromise } from "../../../services/api";

export default function useCancel() {
  const queryClient = useQueryClient();

  return useMutation(
    (cancelData) =>
      apiRequestPromise("put", `cancel/${cancelData.uuid}`, cancelData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("appointments");
      },
    }
  );
}

import React from "react";
import AddButton from "components/common/buttons/AddButton";
import Load from "modules/Checkin/components/LoadsList/components/Load/Load";
import CardError from "components/common/labels/CardError";
import RemoveButton from "components/common/buttons/RemoveButton";
import useValidateAndAddLoad from "modules/Checkin/components/LoadsList/hooks/useValidateAndAddLoad";
import { formatInTimeZone } from "date-fns-tz";
import { parseISO } from "date-fns";

export default function LoadsList({
  loads,
  append,
  remove,
  setError,
  clearErrors,
  error,
  direction,
  location,
}) {
  const {
    validateAndAddLoad,
    referenceNumber,
    setReferenceNumber,
    referenceNumberInput,
    setType,
    type,
    isAdding,
    appointment,
  } = useValidateAndAddLoad(
    clearErrors,
    location,
    setError,
    direction,
    loads,
    append
  );

  if (type === "loads") {
    return (
      <div className="flex flex-col gap-2">
        <label className="font-medium text-base uppercase">Loads</label>
        {loads.length > 0 ? (
          loads.map((load, index) => (
            <Load key={load.load} load={load} index={index} remove={remove} />
          ))
        ) : (
          <p className="p-1 opacity-40">No Loads Added</p>
        )}
        <div className="flex flex-col ">
          <div className="flex flex-col gap-3">
            <input
              className=" w-full p-1 rounded"
              value={referenceNumber}
              ref={referenceNumberInput}
              placeholder="Load, PO or Order Number"
              onChange={(e) => {
                setReferenceNumber(e.target.value);
                clearErrors("loads");
              }}
            />
            <AddButton onClick={validateAndAddLoad} isAdding={isAdding} />
          </div>
          <CardError error={error} />
        </div>
      </div>
    );
  } else if (type === "appointment") {
    return (
      <div className="flex flex-col gap-2">
        <label className="font-medium text-base uppercase">
          Appointment {appointment.appointment_id_3g} -{" "}
          {formatInTimeZone(
            parseISO(appointment.appointment_time),
            "UTC",
            "EEEE LLLL do, yyyy 'at' h:mm a"
          )}
        </label>
        {loads.length > 0 ? (
          <LoadsTable loads={loads} />
        ) : (
          <p className="p-1 opacity-40">No Loads Added</p>
        )}
        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              remove();
              setType("loads");
            }}
            className="bg-amber-300 p-1 rounded"
          >
            Remove Appointment
          </button>
        </div>
        <div className="flex flex-col ">
          <CardError error={error} />
        </div>
      </div>
    );
  }
}

function LoadsTable({ loads, remove }) {
  return (
    <table>
      <thead className="text-sm text-left">
        <tr>
          <th>Load Number</th>
          <th>Origin/Destination</th>
          <th>PO/Order Number</th>
          {remove && <th></th>}
        </tr>
      </thead>
      <tbody className="text-sm">
        {loads.map((load, index) => (
          <tr key={load.id}>
            <td>{load.load}</td>
            <td>
              {load.origin} to {load.destination}
            </td>
            <td>{load.po_number || load.order_number || ""}</td>
            {remove && (
              <td>
                <RemoveButton
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

import { useEffect, useState } from "react";

export default function useChoosePage(userData) {
  const [page, setPage] = useState("login");

  useEffect(() => {
    if (!userData) {
      setPage("scheduler");
    } else if (userData.type === "checkin") {
      setPage("checkin");
    } else if (["dock", "shipping", "receiving"].includes(userData.type)) {
      setPage("checkout");
    } else if (userData.type === "admin") {
      setPage("admin");
    }
  }, [userData]);

  return page;
}

import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";

export function utcToSameLocalTime(dateTimeString) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = zonedTimeToUtc(dateTimeString.slice(0, 10), tz);
  return date;
}

export function getLatestEarlyArrivalDate(loads) {
  let latestEarliestDate;
  loads.forEach((load) => {
    const loadEarliestDate = utcToSameLocalTime(load.earliest);
    if (!latestEarliestDate || loadEarliestDate > latestEarliestDate) {
      latestEarliestDate = loadEarliestDate;
    }
  });
  return latestEarliestDate;
}

export function getEarliestLateArrivalDate(loads) {
  let earliestLatestDate;
  loads.forEach((load) => {
    const loadLatestDate = utcToSameLocalTime(load.latest);
    if (!earliestLatestDate || loadLatestDate < earliestLatestDate) {
      earliestLatestDate = loadLatestDate;
    }
  });
  return earliestLatestDate;
}

export function getStartOfExistingLoadsSharedWindow(loads) {
  let latestEarliestDate;
  loads.forEach((load) => {
    const loadEarliestDate = utcToSameLocalTime(load.earliest);
    if (!latestEarliestDate || loadEarliestDate > latestEarliestDate) {
      latestEarliestDate = loadEarliestDate;
    }
  });

  return latestEarliestDate;
}

export function getEndOfExistingLoadsSharedWindow(loads) {
  let earliestLatestDate;
  loads.forEach((load) => {
    const loadLatestDate = utcToSameLocalTime(load.latest);
    if (!earliestLatestDate || loadLatestDate < earliestLatestDate) {
      earliestLatestDate = loadLatestDate;
    }
  });
  return earliestLatestDate;
}

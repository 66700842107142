import { useState, useRef } from "react";
import { apiRequest } from "services/api";
import LoadValidator from "modules/Checkin/components/LoadsList/components/LoadValidator";
import { zonedTimeToUtc } from "date-fns-tz";
import { locations } from "../../../../../constants/locations";

export default function useValidateAndAddLoad(
  clearErrors,
  location,
  setError,
  direction,
  loads,
  append
) {
  const [type, setType] = useState("loads");
  const [referenceNumber, setReferenceNumber] = useState("");
  const referenceNumberInput = useRef();
  const [isAdding, setIsAdding] = useState(false);
  const [appointment, setAppointment] = useState(null);

  const validateAndAddLoad = (e) => {
    e.preventDefault();
    clearErrors("loads");
    if (!location) {
      setError("loads", {
        message: "Choose a location",
      });
    } else if (!direction) {
      setError("loads", {
        message: "Choose a direction",
      });
    } else if (!referenceNumber) {
      setError("loads", {
        message: "Enter a load or PO number",
      });
    } else {
      setIsAdding(true);
      apiRequest(
        "get",
        `validate?referenceNumber=${referenceNumber}&location=${location}&direction=${direction}`
      )
        .then((response) => {
          if (responseIsAnAppointment(response)) {
            if (loads.length > 0) {
              setError("loads", {
                message:
                  "This load is part of an appointment. Clear existing loads to add appointment.",
              });
              setIsAdding(false);
              return;
            }

            const appointmentLocation = response.appointment.location;
            if (
              locations.northbrook.all.includes(appointmentLocation) &&
              location === "sc"
            ) {
              setError("loads", {
                message: "This appointment is not for Highland South Carolina.",
              });
              setIsAdding(false);
              return;
            } else if (
              locations.southCarolina.all.includes(appointmentLocation) &&
              location === "nb"
            ) {
              setError("loads", {
                message: "This appointment is not for Highland Northbrook.",
              });
              setIsAdding(false);
              return;
            }

            const today = new Date();
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const dateOfAppointment = zonedTimeToUtc(
              response.appointment.appointment_time.slice(0, 10)
            );

            if (
              dateOfAppointment > today &&
              response.appointment.direction === "pickup"
            ) {
              setError("loads", {
                message: "This appointment is in the future.",
              });
              setIsAdding(false);
              return;
            }

            response.loads.forEach((load) => {
              const {
                load_number,
                origin,
                destination,
                earliest,
                latest,
                appointment,
                po_number,
                order_number,
              } = load;

              append({
                load: load_number,
                origin,
                destination,
                earliest,
                latest,
                appointment,
                po_number,
                order_number,
              });

              setAppointment(response.appointment);
            });
            setType("appointment");
          } else {
            const {
              load_num,
              origin,
              destination,
              earliest,
              latest,
              appointment,
              po_number,
              order_number,
            } = response;

            try {
              new LoadValidator(
                loads,
                load_num,
                origin,
                destination,
                earliest,
                latest,
                appointment,
                po_number,
                order_number,
                direction
              ).validate();
            } catch (error) {
              setError("loads", {
                message: error.message,
              });
              setIsAdding(false);
              return;
            }

            append({
              load: load_num,
              origin,
              destination,
              earliest,
              latest,
              appointment,
              po_number,
              order_number,
            });
          }
          setReferenceNumber("");
          setIsAdding(false);
          clearErrors("confirm");
        })
        .catch((error) => {
          setError("loads", {
            message: error.response.data,
          });
          setIsAdding(false);
        });
    }
  };

  return {
    validateAndAddLoad,
    referenceNumber,
    setIsAdding,
    setReferenceNumber,
    referenceNumberInput,
    setType,
    type,
    isAdding,
    appointment,
  };
}

function responseIsAnAppointment(response) {
  return "loads" in response;
}

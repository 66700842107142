import { useRef, useState } from "react";
import { apiRequest } from "services/api";
import AddButton from "components/common/buttons/AddButton";
import CardError from "components/common/labels/CardError";
import LoadValidator from "modules/common/LoadValidator/LoadValidator";

export default function EditLoadsModalAdder({
  loads,
  setLoads,
  add,
  setAdd,
  appointment,
  location,
}) {
  const [referenceNumber, setReferenceNumber] = useState("");
  const referenceNumberInput = useRef();
  const [error, setError] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  const validateAndAddLoad = () => {
    if (!referenceNumber) {
      setError("Enter a load, PO or order number");
      return;
    }

    setIsAdding(true);
    apiRequest(
      "get",
      `validate-scheduler?referenceNumber=${referenceNumber}&location=${location}&direction=${appointment.direction}`
    )
      .then((response) => {
        const {
          load_num,
          origin,
          destination,
          earliest,
          latest,
          po_number,
          order_number,
        } = response;

        if (response.appointment) {
          setError("This load is part of another appointment.");
          setIsAdding(false);
          return;
        } else {
          try {
            new LoadValidator(
              loads,
              load_num,
              origin,
              destination,
              earliest,
              latest,
              appointment,
              po_number,
              order_number,
              appointment.direction
            ).validate();
          } catch (error) {
            setError(error.message);
            setIsAdding(false);
            return;
          }
        }

        const updatedLoads = [...loads];
        updatedLoads.push({
          destination,
          earliest,
          latest,
          load_number: load_num,
          order_number,
          origin,
          po_number,
        });
        setLoads(updatedLoads);

        const updatedAdd = [...add];
        updatedAdd.push({
          destination,
          earliest,
          latest,
          load_number: load_num,
          order_number,
          origin,
          po_number,
        });
        setAdd(updatedAdd);

        setReferenceNumber("");
        referenceNumberInput.current.focus();
        setIsAdding(false);
        setError("");
      })
      .catch((error) => {
        setError(error.response.data);
        setIsAdding(false);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-3">
        <input
          className=" w-full rounded p-1"
          value={referenceNumber}
          ref={referenceNumberInput}
          placeholder="Load, PO or Order Number"
          onChange={(e) => {
            setReferenceNumber(e.target.value);
          }}
        />
        <AddButton onClick={validateAndAddLoad} isAdding={isAdding} />
      </div>
      <CardError error={error} />
    </div>
  );
}

import { useQuery } from "react-query";
import { apiRequest } from "services/api";
import { formatInTimeZone } from "date-fns-tz";

export default function useGetAppointmentOptions(
  loads,
  location,
  direction,
  date
) {
  const getAppointmentOptions = () => {
    return apiRequest(
      "get",
      `appointment-options?loadNumber=${
        loads[0].load
      }&location=${location}&direction=${direction}&isAppointment=false&date=${formatDate(
        date
      )}`
    );
  };

  return useQuery("appointmentOptions", getAppointmentOptions, {
    enabled: loads?.length > 0 && !!date,
  });
}

function formatDate(date) {
  if (!date) {
    return;
  }
  return formatInTimeZone(date, "utc", "yyyyMMdd");
}

export default function AppointmentTableLayoutHeader({ headerGroups }) {
  return (
    <div className="font-bold mb-6 bg-slate-300 px-7 py-3">
      {headerGroups.map((headerGroup) => (
        <div {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <div {...column.getHeaderProps()}>{column.render("Header")}</div>
          ))}
        </div>
      ))}
    </div>
  );
}

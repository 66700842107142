import RemoveButton from "components/common/buttons/RemoveButton";

export default function Load({ load, index, remove }) {
  return (
    <div key={load.id} className="flex justify-between content-center ">
      <div className="flex flex-col sm:flex-row sm:gap-10 w-full p-1 bg-slate-300 rounded text-sm">
        <div>{load.load}</div>
        <div>
          {load.origin} to {load.destination}
        </div>
        <div>{load.po_number || load.order_number || ""}</div>
      </div>
      <div className="px-2 flex items-center">
        <RemoveButton
          onClick={(e) => {
            e.preventDefault();
            remove(index);
          }}
        />
      </div>
    </div>
  );
}

import React from "react";
import { useFlexLayout, useGlobalFilter, useTable } from "react-table";
import AppointmentTableLayout from "./AppointmentTableLayout";
import DoorNumModal from "./DoorNumModal";
import useDoorNumModal from "./hooks/useDoorNumModal";
import useCheckoutModal from "./hooks/useCheckoutModal";
import CheckoutModal from "./CheckoutModal";
import { format, parseISO } from "date-fns";
import Button from "../common/buttons/Button";
import { useQueryClient } from "react-query";
import AppointmentFilterGlobalFilter from "./AppointmentTableGlobalFilter";
import AppointmentTableLayoutHeader from "./AppointmentTableLayoutHeader";
import { formatInTimeZone } from "date-fns-tz";
import useCancelModal from "./hooks/useCancelModal";
import CancelModal from "./CancelModal";
import useEditInfoModal from "./hooks/useEditInfoModal";
import EditInfoModal from "./EditInfoModal";
import useEditLoadsModal from "./hooks/useEditLoadsModal";
import EditLoadsModal from "./EditLoadsModal";
import Logo from "modules/common/Logo/Logo";

export default function AppointmentTable({ tableData, heading, location }) {
  const queryClient = useQueryClient();

  const {
    showDoorNumModal,
    doorNumModalAppointment,
    openDoorNumModal,
    closeDoorNumModal,
  } = useDoorNumModal();

  const {
    showCheckoutModal,
    checkoutModalAppointment,
    openCheckoutModal,
    closeCheckoutModal,
  } = useCheckoutModal();

  const {
    showCancelModal,
    cancelModalAppointment,
    openCancelModal,
    closeCancelModal,
  } = useCancelModal();

  const {
    showEditInfoModal,
    editInfoModalAppointment,
    editInfoModalField,
    openEditInfoModal,
    closeEditInfoModal,
  } = useEditInfoModal();

  const {
    showEditLoadsModal,
    editLoadsModalAppointment,
    openEditLoadsModal,
    closeEditLoadsModal,
  } = useEditLoadsModal();

  const [columns, data] = React.useMemo(() => {
    const columns = [
      {
        Header: "Cancel",
        Cell: (cell) => {
          if (cell.row.values.Checkout) {
            return "";
          } else {
            return (
              <button
                className="rounded bg-amber-300 w-4/5"
                onClick={() => {
                  openCancelModal(cell.row.original);
                }}
              >
                Cancel
              </button>
            );
          }
        },

        width: 75,
      },
      {
        Header: "Checkin",
        accessor: (row) => {
          const checkinTime = parseISO(row.checkin);
          return format(checkinTime, "h:mm a");
        },
        width: 75,
      },
      {
        Header: "Appointment",
        accessor: (row) => {
          if (row.appointment) {
            return formatInTimeZone(parseISO(row.appointment), "UTC", "h:mm a");
          } else if (row.appointment_time) {
            return formatInTimeZone(
              parseISO(row.appointment_time),
              "UTC",
              "h:mm a"
            );
          } else {
            return "--";
          }
        },
        width: 75,
      },
      {
        Header: "Direction",
        accessor: (row) =>
          row.direction === "delivery" ? "Delivery" : "Pickup",
        width: 75,
      },
      {
        Header: "Carrier",
        accessor: "carrier_name",
        Cell: (cell) => (
          <button
            className="rounded bg-amber-300 w-4/5"
            onClick={() => {
              openEditInfoModal(cell.row.original, {
                fieldName: "Carrier",
                field: "carrier_name",
              });
            }}
          >
            {cell.value}
          </button>
        ),
        width: 125,
      },
      {
        Header: "Trailer",
        accessor: "trailer_num",
        Cell: (cell) => (
          <button
            className="rounded bg-amber-300 w-4/5"
            onClick={() => {
              openEditInfoModal(cell.row.original, {
                fieldName: "Trailer",
                field: "trailer_num",
              });
            }}
          >
            {cell.value}
          </button>
        ),
        width: 75,
      },
      {
        Header: "Driver",
        accessor: "driver_name",
        Cell: (cell) => (
          <button
            className="rounded bg-amber-300 w-4/5"
            onClick={() => {
              openEditInfoModal(cell.row.original, {
                fieldName: "Driver",
                field: "driver_name",
              });
            }}
          >
            {cell.value}
          </button>
        ),
        width: 125,
      },
      {
        id: "load_number",
        Header: <div className="px-1">Load Number</div>,
        accessor: (row) => {
          return row.loads.map((load) => load.load_number);
        },
        Cell: ({ value }) => {
          return (
            <div className="flex flex-col rounded-l bg-slate-200 px-1">
              {value.map((load_number, index) => (
                <div key={index}>{load_number}</div>
              ))}
            </div>
          );
        },
        width: 100,
      },
      {
        id: "po_number",
        Header: "PO/Order Number",
        accessor: (row) => {
          return row.loads.map((load) => {
            if (row.direction === "pickup") {
              return load.po_number ? load.po_number : "--";
            } else if (row.direction === "delivery") {
              return load.order_number ? load.order_number : "--";
            }
            return "--";
          });
        },
        Cell: ({ value }) => {
          return (
            <div className="flex flex-col bg-slate-200">
              {value.map((po_number, index) => (
                <div key={index}>{po_number}</div>
              ))}
            </div>
          );
        },
        width: 125,
      },
      {
        id: "origin_destination",
        Header: "Origin/Destination",
        accessor: (row) => {
          return row.loads.map((load) =>
            row.direction === "pickup" ? load.destination : load.origin
          );
        },
        Cell: ({ value }) => {
          return (
            <div className="flex flex-col  bg-slate-200 rounded-br">
              {value.map((origin_destination, index) => (
                <div key={index}>{origin_destination}</div>
              ))}
            </div>
          );
        },
        width: 175,
      },
      {
        id: "edit",
        Cell: (cell) => {
          if (cell.row.original.checkout) {
            return "";
          } else {
            return (
              <button
                className="bg-amber-300 rounded-r w-4/5"
                onClick={() => {
                  openEditLoadsModal(cell.row.original);
                }}
              >
                Edit
              </button>
            );
          }
        },
        width: 50,
      },
      {
        Header: <div className="pl-3">Door</div>,
        accessor: "door_num",
        Cell: (cell) => {
          if (cell.row.original.checkout) {
            return (
              <div className="pl-3 text-center w-2/3">
                {cell.value ? cell.value : "--"}
              </div>
            );
          } else {
            return (
              <div className="pl-3">
                <button
                  className="rounded bg-amber-300 w-2/3"
                  onClick={() => {
                    openDoorNumModal(cell.row.original);
                  }}
                >
                  {cell.value ? cell.value : "--"}
                </button>
              </div>
            );
          }
        },
        width: 75,
      },
      {
        Header: "Checkout",
        accessor: (row) => {
          if (row.checkout) {
            return format(parseISO(row.checkout), "h:mm a");
          } else {
            return row.checkout;
          }
        },
        Cell: (cell) => {
          return cell.value ? (
            cell.value
          ) : (
            <button
              className="rounded bg-amber-300 w-4/5"
              onClick={() => {
                openCheckoutModal(cell.row.original);
              }}
            >
              Checkout
            </button>
          );
        },
        width: 75,
      },
    ];
    return [columns, tableData];
  }, [
    tableData,
    openCancelModal,
    openEditInfoModal,
    openEditLoadsModal,
    openDoorNumModal,
    openCheckoutModal,
  ]);

  const tableInstance = useTable(
    { columns, data },
    useFlexLayout,
    useGlobalFilter
  );

  return (
    <>
      {showDoorNumModal && (
        <DoorNumModal
          isOpen={showDoorNumModal}
          appointment={doorNumModalAppointment}
          closeDoorNumModal={closeDoorNumModal}
        />
      )}
      {showCheckoutModal && (
        <CheckoutModal
          isOpen={showCheckoutModal}
          appointment={checkoutModalAppointment}
          closeCheckoutModal={closeCheckoutModal}
        />
      )}
      {showCancelModal && (
        <CancelModal
          isOpen={showCancelModal}
          appointment={cancelModalAppointment}
          closeCancelModal={closeCancelModal}
        />
      )}
      {showEditInfoModal && (
        <EditInfoModal
          isOpen={showEditInfoModal}
          appointment={editInfoModalAppointment}
          field={editInfoModalField}
          closeEditInfoModal={closeEditInfoModal}
        />
      )}
      {showEditLoadsModal && (
        <EditLoadsModal
          isOpen={showEditLoadsModal}
          appointment={editLoadsModalAppointment}
          closeEditLoadsModal={closeEditLoadsModal}
          location={location}
        />
      )}
      <div className="fixed top-0 bg-[#F1F5F9] w-full p-4 flex justify-between items-center">
        <div className="w-1/3 flex justify-start ">
          <div>
            <Logo />
          </div>
        </div>
        <div className="w-1/3 flex flex-col gap-3 justify-center text-center content-center">
          <h1 className="text-2xl font-semibold">{heading}</h1>
        </div>
        <div className="w-1/3 flex flex-col gap-3 justify-end">
          <div className="flex justify-end">
            <Button
              onClick={() => {
                localStorage.clear();
                queryClient.invalidateQueries("userData");
              }}
              className="m-1 px-2"
            >
              Logout
            </Button>
          </div>
          <div className="flex justify-end">
            <AppointmentFilterGlobalFilter {...tableInstance} />
          </div>
        </div>
      </div>
      <div className="fixed top-[150px] w-full">
        <AppointmentTableLayoutHeader {...tableInstance} />
      </div>
      <div className="p-4 mt-[200px]">
        <AppointmentTableLayout {...tableInstance} />
      </div>
    </>
  );
}

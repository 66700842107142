import React from "react";

export default function AppointmentFilterGlobalFilter({
  state,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(state.globalFilter || "");

  const onChange = (value) => {
    setGlobalFilter(value || undefined);
  };

  return (
    <div className="flex gap-2 items-center justify-center">
      <label className="font-bold ">Filter: </label>
      <input
        className="p-1 rounded"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      <button
        className="bg-amber-300 px-1 rounded"
        onClick={() => {
          setValue("");
          onChange("");
        }}
      >
        Clear
      </button>
    </div>
  );
}

import React from "react";
import TextInput from "../components/common/inputs/TextInput";
import { useForm } from "react-hook-form";
import Button from "../components/common/buttons/Button";
import Card from "../components/common/containers/Card";
import CardLabel from "../components/common/labels/CardLabel";
import CardError from "../components/common/labels/CardError";
import { loginRequest } from "../services/api";
import Logo from "modules/common/Logo/Logo";

export default function Login({ setPage }) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    loginRequest(data.username, data.password).catch((error) => {
      setError("form", {
        message: "Unable to log in with provided credentials",
      });
    });
  };

  return (
    <>
      <div className="w-full p-4 flex justify-between items-center">
        <div className="w-1/3 flex justify-start ">
          <div>
            <Logo />
          </div>
        </div>
        <div className="w-1/3 flex justify-center text-center content-center">
          <h1 className=" text-2xl font-semibold">Highland Login</h1>
        </div>
        <div className="w-1/3 flex justify-end">
          <Button
            onClick={() => {
              setPage("scheduler");
            }}
            className="p-2"
          >
            Back to Scheduler
          </Button>
        </div>
      </div>
      <div className="sm:w-1/2 lg:w-1/4 mx-auto flex justify-center content-center py-8">
        <form
          className="w-full flex flex-col gap-3 px-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Card>
            <CardLabel text="Username" />
            <TextInput
              name="username"
              register={register}
              rules={{
                required: "Provide your username",
                onChange: () => {
                  clearErrors("form");
                },
              }}
            />
            <CardError error={errors?.username?.message} />
          </Card>
          <Card>
            <CardLabel text="Password" />
            <TextInput
              name="password"
              register={register}
              rules={{
                required: "Provide your password",
                onChange: () => {
                  clearErrors("form");
                },
              }}
              type="password"
            />
            <CardError error={errors?.password?.message} />
          </Card>
          <Button>Submit</Button>
          <p className="text-sm text-red-500">{errors?.form?.message}</p>
        </form>
      </div>
    </>
  );
}

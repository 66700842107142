import ReactModal from "react-modal";
import Button from "../common/buttons/Button";
import React, { useState } from "react";
import useCancel from "./hooks/useCancel";
import format from "date-fns/format";

export default function CancelModal({ isOpen, appointment, closeCancelModal }) {
  const mutation = useCancel();

  const [adding, setAdding] = useState(false);

  const onSubmit = () => {
    setAdding(true);
    mutation
      .mutateAsync({
        ...appointment,
        timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      })
      .then(() => {
        closeCancelModal();
      });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Cancel?"
      style={{
        content: {
          width: 450,
          height: 150,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <div className="flex flex-col content-evenly gap-8">
        <div className="text-lg text-center">
          Are you sure you want to cancel this Checkin?
        </div>
        <div className="flex gap-2">
          <Button
            className="w-full"
            onClick={() => {
              onSubmit();
            }}
            submitting={adding}
          >
            Submit
          </Button>
          <Button
            className="bg-red-500 w-full"
            onClick={() => {
              closeCancelModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ReactModal>
  );
}

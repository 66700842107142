import { useState, useRef } from "react";
import Card from "components/common/containers/Card";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import { isValid, parse, format } from "date-fns";
import FocusTrap from "focus-trap-react";
import CardError from "components/common/labels/CardError";
import { useQueryClient } from "react-query";

export default function DatePicker({
  error,
  appointmentDate,
  direction,
  setSelectedDate,
  earliest,
  latest,
}) {
  const queryClient = useQueryClient();

  const [selected, setSelected] = useState(appointmentDate);
  const [inputValue, setInputValue] = useState(() => {
    if (!appointmentDate) {
      return undefined;
    }
    return format(appointmentDate, "y-MM-dd");
  });
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "auto-start",
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
    if (isValid(date)) {
      setSelected(date);
      setSelectedDate(date);
      queryClient.removeQueries("modalOptions");
    } else {
      setSelected(undefined);
      queryClient.setQueryData("modalOptions", undefined);
    }
  };

  const handleButtonClick = () => {
    if (!isPopperOpen) {
      setIsPopperOpen(true);
    } else {
      closePopper();
    }
  };

  const handleDaySelect = (date) => {
    setSelected(date);
    setSelectedDate(date);
    queryClient.removeQueries("modalOptions");
    if (date) {
      setInputValue(format(date, "y-MM-dd"));
      closePopper();
    } else {
      setInputValue("");
    }
  };

  return (
    <Card>
      <div ref={popperRef} className="flex gap-2">
        <input
          type="text"
          className="rounded border-none p-1"
          value={inputValue}
          onChange={handleInputChange}
        />
        <button
          ref={buttonRef}
          type="button"
          aria-label="Pick a date"
          onClick={handleButtonClick}
          className="w-full rounded bg-amber-300 p-1"
        >
          Choose Date
        </button>
        {isPopperOpen && (
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
              onDeactivate: closePopper,
            }}
          >
            <div
              tabIndex={-1}
              style={popper.styles.popper}
              className="rounded border-2 bg-white"
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
            >
              <DayPicker
                initialFocus={isPopperOpen}
                mode="single"
                selected={selected}
                onSelect={handleDaySelect}
                disabled={disabledDays(earliest, latest, direction)}
                defaultMonth={showMonth(direction, earliest)}
              />
            </div>
          </FocusTrap>
        )}
      </div>
      <CardError error={error} />
    </Card>
  );
}

export function disabledDays(earliest, latest, direction) {
  if (direction === "pickup") {
    const now = new Date();
    const disabledDays = [{ before: now.setDate(now.getDate()) }];
    disabledDays.push({ before: new Date(earliest) });
    disabledDays.push({ after: new Date(latest) });
    return disabledDays;
  } else {
    return [{ before: new Date() }];
  }
}

export function showMonth(direction, earliest) {
  if (direction === "pickup") {
    return new Date(earliest);
  }
  return new Date();
}

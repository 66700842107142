import Button from "components/common/buttons/Button";
import { useEffect, useState } from "react";
import { apiRequest } from "services/api";
import { formatInTimeZone } from "date-fns-tz";
import { parseISO } from "date-fns";

export default function LoadSearch({ filterInput }) {
  const [appointmentResult, setAppointmentResult] = useState(null);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setAppointmentResult(null);
  }, [filterInput]);

  async function handleSearch(type) {
    setError(null);
    setSearching(true);
    if (type === "appointment") {
      await apiRequest(
        "GET",
        `appointments/search?appointmentNumber=${filterInput}`
      )
        .then((data) => setAppointmentResult(data))
        .catch((error) => setError(error.response.data));
    } else if (type === "load") {
      await apiRequest("GET", `appointments/search?loadNumber=${filterInput}`)
        .then((data) => setAppointmentResult(data))
        .catch((error) => setError(error.response.data));
    } else if (type === "poNumber") {
      await apiRequest("GET", `appointments/search?poNumber=${filterInput}`)
        .then((data) => setAppointmentResult(data))
        .catch((error) => setError(error.response.data));
    } else if (type === "orderNumber") {
      await apiRequest("GET", `appointments/search?orderNumber=${filterInput}`)
        .then((data) => setAppointmentResult(data))
        .catch((error) => setError(error.response.data));
    }
    setSearching(false);
  }

  if (!appointmentResult) {
    return (
      <div className="py-3 mt-[190px] flex flex-col items-center gap-4">
        <div>
          No future appointments match this filter. Search past loads by:
        </div>
        <div className="flex gap-4">
          {searching ? (
            <div>Searching...</div>
          ) : (
            <>
              <Button
                className="w-fit px-2"
                onClick={() => {
                  handleSearch("appointment");
                }}
              >
                By Appointment #
              </Button>
              <Button
                className="w-fit px-2"
                onClick={() => {
                  handleSearch("load");
                }}
              >
                By Load #
              </Button>
              <Button
                className="w-fit px-2"
                onClick={() => {
                  handleSearch("poNumber");
                }}
              >
                By PO #
              </Button>
              <Button
                className="w-fit px-2"
                onClick={() => {
                  handleSearch("orderNumber");
                }}
              >
                By Order #
              </Button>
            </>
          )}
        </div>
        {error && <div>{error}</div>}
      </div>
    );
  }

  return (
    <div className="py-3 mt-[190px] flex flex-col max-w-5xl mx-auto justify-center">
      <div>
        <div>
          <span className="font-bold">Appointment #: </span>
          {appointmentResult.appointment_id_3g}
        </div>
        <div>
          <span className="font-bold">Date/Time: </span>
          {formatInTimeZone(
            parseISO(appointmentResult.appointment_time),
            "UTC",
            "M/d/yy h:mm a"
          )}
        </div>
        <div>
          <span className="font-bold">Direction: </span>
          {appointmentResult.direction === "pickup" ? "Pickup" : "Delivery"}
        </div>
        <div>
          <span className="font-bold">Location: </span>
          {appointmentResult.location}
        </div>
        <div>
          <span className="font-bold">Broker: </span>
          {appointmentResult.broker}
        </div>
        <div>
          <span className="font-bold">Scheduler: </span>
          {appointmentResult.scheduler}
        </div>
        <div>
          <span className="font-bold">Phone: </span>
          {appointmentResult.phone}
        </div>
        <div>
          <span className="font-bold">Email: </span>
          {appointmentResult.email}
        </div>
      </div>
      <table className="mt-2">
        <thead>
          <tr>
            <th className="pr-3 text-left">Load Number</th>
            <th className="pr-3 text-left">PO/Order Number</th>
            <th className="pr-3 text-left">Origin/Destination</th>
          </tr>
        </thead>
        <tbody className="h-fit">
          {appointmentResult.loads.map((load) => (
            <tr key={load.load_number}>
              <td className="pr-3">{load.load_number}</td>
              <td className="pr-3">
                {load.po_number || load.order_number || "no po or order number"}
              </td>
              <td className="pr-3">
                {load.origin} to {load.destination}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

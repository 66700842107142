export const locations = {
  northbrook: {
    all: ["HIGHLAND NORTHBROOK", "RAW MATERIAL MAIN"],
    pickup: ["HIGHLAND NORTHBROOK"],
    delivery: ["RAW MATERIAL MAIN"],
  },
  southCarolina: {
    all: ["HIGHLAND SC", "RAW MATERIAL SC"],
    pickup: ["HIGHLAND SC"],
    delivery: ["RAW MATERIAL SC"],
  },
};

import RemoveButton from "../common/buttons/RemoveButton";

export default function EditLoadsModalLoads({
  loads,
  setLoads,
  remove,
  setRemove,
  direction,
}) {
  return (
    <div className="grid grid-cols-5 mb-6">
      <div className="font-bold">Load Number</div>
      <div className="font-bold">
        {direction === "pickup" ? "PO Number" : "Order Number"}
      </div>
      <div className="font-bold col-span-2">
        {direction === "pickup" ? "Destination" : "Origin"}
      </div>
      <div></div>
      {loads.map((load, index) => (
        <Load
          key={load.load_number}
          {...{ load, loads, setLoads, remove, setRemove, direction, index }}
        />
      ))}
    </div>
  );
}

function Load({ load, loads, setLoads, remove, setRemove, direction, index }) {
  const handleRemove = (index) => {
    const updatedLoads = [...loads];
    const removedLoad = updatedLoads.splice(index, 1);
    setLoads(updatedLoads);

    const updatedRemove = [...remove];
    updatedRemove.push(...removedLoad);
    setRemove(updatedRemove);
  };

  return (
    <>
      <div>{load.load_number}</div>
      <div>{direction === "pickup" ? load.po_number : load.order_number}</div>
      <div className="col-span-2">
        {direction === "pickup" ? load.destination : load.origin}
      </div>
      <div>
        <RemoveButton
          onClick={() => {
            handleRemove(index);
          }}
        >
          Remove
        </RemoveButton>
      </div>
    </>
  );
}

import { useMutation, useQueryClient } from "react-query";
import { apiRequest } from "services/api";

export default function useDelete() {
  const queryClient = useQueryClient();

  return useMutation(
    (uuid) => apiRequest("delete", `delete-appointment/${uuid}`, ""),
    {
      onSettled: () => {
        queryClient.invalidateQueries("scheduledAppointments");
      },
    }
  );
}

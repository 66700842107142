import ScheduledAppointmentsTable from "./components/ScheduleAppointmentsTable/ScheduledAppointmentsTable";
import useGetScheduledAppointments from "./hooks/useGetScheduledAppointments";
import useDoors from "./hooks/useDoors";

export default function Admin({ location }) {
  const { data: scheduledAppointments } = useGetScheduledAppointments();
  const { nbShipping, nbReceiving, scShipping, scReceiving, updateDoors } =
    useDoors();

  if (
    !scheduledAppointments ||
    !nbShipping ||
    !nbReceiving ||
    !scShipping ||
    !scReceiving
  )
    return "Loading...";

  return (
    <>
      <ScheduledAppointmentsTable
        location={location}
        scheduledAppointments={scheduledAppointments}
        shippingDoors={location === "nb" ? nbShipping : scShipping}
        receivingDoors={location === "nb" ? nbReceiving : scReceiving}
        updateDoors={updateDoors}
      />
    </>
  );
}

import { useState, useRef, useEffect } from "react";
import Card from "components/common/containers/Card";
import CardLabel from "components/common/labels/CardLabel";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import { isValid, parse, format, max, min } from "date-fns";
import FocusTrap from "focus-trap-react";
import { useQueryClient } from "react-query";
import {
  getLatestEarlyArrivalDate,
  getEarliestLateArrivalDate,
} from "modules/common/dateUtilities";

export default function DatePicker({ setDate, date, loads, direction }) {
  const queryClient = useQueryClient();

  useEffect(() => {
    setInputValue(date ? format(date, "y-MM-dd") : undefined);
  }, [date]);

  const [inputValue, setInputValue] = useState(() => {
    return date ? format(date, "y-MM-dd") : undefined;
  });
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "auto-start",
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
    if (isValid(date)) {
      setDate(date);
      queryClient.removeQueries("appointmentOptions");
    } else {
      setDate(undefined);
      queryClient.setQueryData("appointmentOptions", undefined);
    }
  };

  const handleButtonClick = () => {
    if (!isPopperOpen) {
      setIsPopperOpen(true);
    } else {
      closePopper();
    }
  };

  const handleDaySelect = (date) => {
    setDate(date);
    queryClient.removeQueries("appointmentOptions");
    if (date) {
      setInputValue(format(date, "y-MM-dd"));
      closePopper();
    } else {
      setInputValue("");
    }
  };

  return (
    <Card>
      <CardLabel text={`Appointment Date`} />
      <div ref={popperRef} className="flex gap-2">
        <input
          type="text"
          className="rounded border-none p-1"
          value={inputValue}
          onChange={handleInputChange}
        />
        <button
          ref={buttonRef}
          type="button"
          aria-label="Pick a date"
          onClick={handleButtonClick}
          className="rounded bg-amber-300 p-1"
        >
          Choose Date
        </button>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: buttonRef.current,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="rounded border-2 bg-white"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              selected={date}
              onSelect={handleDaySelect}
              disabled={disabledDays(direction, loads)}
              defaultMonth={showMonth(direction, loads)}
            />
          </div>
        </FocusTrap>
      )}
    </Card>
  );
}

export function disabledDays(direction, loads) {
  if (direction === "pickup") {
    const now = new Date();
    const disabledDays = [{ before: now.setDate(now.getDate() + 1) }];
    disabledDays.push({ before: getLatestEarlyArrivalDate(loads) });
    disabledDays.push({ after: getEarliestLateArrivalDate(loads) });
    return disabledDays;
  } else {
    return [{ before: new Date() }];
  }
}

export function showMonth(direction, loads) {
  if (direction === "pickup") {
    return getLatestEarlyArrivalDate(loads);
  }
  return new Date();
}

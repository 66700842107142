import React, { useCallback } from "react";

export default function useEditInfoModal() {
  const [showEditInfoModal, setShowEditInfoModal] = React.useState(false);
  const [editInfoModalAppointment, setEditInfoModalAppointment] =
    React.useState(null);
  const [editInfoModalField, setEditInfoModalField] = React.useState("");

  const openEditInfoModal = useCallback((appointment, field) => {
    setEditInfoModalAppointment(appointment);
    setEditInfoModalField(field);
    setShowEditInfoModal(true);
  }, []);

  const closeEditInfoModal = () => {
    setShowEditInfoModal(false);
    setEditInfoModalField("");
    setEditInfoModalAppointment(null);
  };

  return {
    showEditInfoModal,
    setShowEditInfoModal,
    editInfoModalAppointment,
    setEditInfoModalAppointment,
    editInfoModalField,
    setEditInfoModalField,
    openEditInfoModal,
    closeEditInfoModal,
  };
}

import { useQuery } from "react-query";
import { apiRequest } from "services/api";

export default function useGetAppointmentOptions(
  load_number,
  location,
  direction,
  date
) {
  const getAppointmentOptions = () => {
    return apiRequest(
      "get",
      `appointment-options?loadNumber=${load_number}&location=${location}&direction=${direction}&isAppointment=true&date=${getDate(
        date
      )}`
    );
  };

  return useQuery("modalOptions", getAppointmentOptions, {
    enabled: !!load_number && !!date,
    retry: false,
  });
}

function getDate(date) {
  if (date) {
    return date.toISOString();
  } else {
    return undefined;
  }
}

import useCreateScheduledAppointmentsTable from "modules/Admin/hooks/useCreateScheduledAppointmentsTable";
import Button from "components/common/buttons/Button";
import { useQueryClient } from "react-query";
import useScheduledAppointmentsTableFilter from "modules/Admin/hooks/useScheduledAppointmentsTableFilter";
import useDeleteModal from "modules/Admin/components/ScheduleAppointmentsTable/hooks/useDeleteModal";
import DeleteModal from "modules/Admin/components/ScheduleAppointmentsTable/components/DeleteModal/DeleteModal";
import useAppointmentTimeModal from "modules/Admin/components/ScheduleAppointmentsTable/hooks/useAppointmentTimeModal";
import AppointmentTimeModal from "modules/Admin/components/ScheduleAppointmentsTable/components/AppointmentModal/AppointmentTimeModal";
import useEditLoadsModal from "modules/Admin/components/ScheduleAppointmentsTable/hooks/useEditLoadsModal";
import EditLoadsModal from "modules/Admin/components/ScheduleAppointmentsTable/components/EditLoadsModal/EditLoadsModal";
import Logo from "modules/common/Logo/Logo";
import LoadSearch from "modules/Admin/components/ScheduleAppointmentsTable/components/LoadSearch";
import { useState } from "react";

export default function ScheduledAppointmentsTable({
  scheduledAppointments,
  location,
  shippingDoors,
  receivingDoors,
  updateDoors,
}) {
  const queryClient = useQueryClient();

  const {
    showDeleteModal,
    deleteModalAppointment,
    openDeleteModal,
    closeDeleteModal,
  } = useDeleteModal();

  const {
    showAppointmentTimeModal,
    appointmentTimeModalAppointment,
    openAppointmentTimeModal,
    closeAppointmentTimeModal,
  } = useAppointmentTimeModal();

  const {
    showEditLoadsModal,
    editLoadsModalAppointment,
    openEditLoadsModal,
    closeEditLoadsModal,
  } = useEditLoadsModal();

  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
  } = useCreateScheduledAppointmentsTable(
    scheduledAppointments,
    openDeleteModal,
    openEditLoadsModal,
    openAppointmentTimeModal
  );

  const { value, setValue, setFilter } = useScheduledAppointmentsTableFilter(
    setGlobalFilter,
    state
  );

  const [shippingDoorsInput, setShippingDoorsInput] = useState(shippingDoors);
  const [receivingDoorsInput, setReceivingDoorsInput] =
    useState(receivingDoors);
  const [doorsMessage, setDoorsMessage] = useState("");

  function saveDoors() {
    const areShippingDoorsValid = /^\d+(,\d+)*$/.test(shippingDoorsInput);
    const areReceivingDoorsValid = /^\d+(,\d+)*$/.test(receivingDoorsInput);

    if (!(areShippingDoorsValid && areReceivingDoorsValid)) {
      setDoorsMessage("error");
      return;
    }

    setDoorsMessage("saved");

    if (location === "nb") {
      updateDoors({
        location: "nb",
        shipping: shippingDoorsInput,
        receiving: receivingDoorsInput,
      });
    } else {
      console.log({
        location: "sc",
        shipping: shippingDoorsInput,
        receiving: receivingDoorsInput,
      });
    }
  }

  return (
    <>
      {showDeleteModal && (
        <DeleteModal
          isOpen={showDeleteModal}
          appointment={deleteModalAppointment}
          closeModal={closeDeleteModal}
        />
      )}
      {showAppointmentTimeModal && (
        <AppointmentTimeModal
          isOpen={showAppointmentTimeModal}
          appointment={appointmentTimeModalAppointment}
          closeModal={closeAppointmentTimeModal}
        />
      )}
      {showEditLoadsModal && (
        <EditLoadsModal
          isOpen={showEditLoadsModal}
          appointment={editLoadsModalAppointment}
          closeEditLoadsModal={closeEditLoadsModal}
          // location={location}
        />
      )}
      <div className="fixed top-0 flex w-full items-center justify-between bg-[#F1F5F9] p-4">
        <div className="flex w-1/4 justify-start ">
          <div>
            <Logo />
          </div>
        </div>
        <div className="flex w-1/2 flex-col content-center justify-center gap-3 text-center">
          <h1 className="text-2xl font-semibold">
            {location === "nb" ? "Northbrook " : "SC "} Admin
          </h1>
          <div className="flex justify-evenly">
            <div className="flex gap-2">
              <div>Shipping Doors:</div>
              <input
                className={
                  doorsMessage === "saved"
                    ? "border-2 border-green-500"
                    : doorsMessage === "error"
                    ? "border-2 border-red-500"
                    : ""
                }
                defaultValue={shippingDoors}
                onChange={(e) => {
                  setDoorsMessage("");
                  setShippingDoorsInput(e.target.value);
                }}
              />
            </div>
            <div className="flex gap-2">
              <div>Receiving Doors:</div>
              <input
                className={
                  doorsMessage === "saved"
                    ? "border-2 border-green-500"
                    : doorsMessage === "error"
                    ? "border-2 border-red-500"
                    : ""
                }
                defaultValue={receivingDoors}
                onChange={(e) => {
                  setDoorsMessage("");
                  setReceivingDoorsInput(e.target.value);
                }}
              />
            </div>
            <button
              className="rounded bg-amber-300 px-1"
              onClick={() => saveDoors()}
            >
              Save
            </button>
          </div>
        </div>
        <div className="flex w-1/4 flex-col justify-end gap-3">
          <div className="flex justify-end">
            <Button
              onClick={() => {
                localStorage.clear();
                queryClient.invalidateQueries("userData");
              }}
              className="m-1 px-2"
            >
              Logout
            </Button>
          </div>
          <div className="flex justify-end">
            <div className="flex justify-start">
              <div className="flex items-center gap-2">
                <label className="font-bold ">Filter: </label>
                <input
                  className="rounded p-1"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    setFilter(e.target.value);
                  }}
                />
                <button
                  className="rounded bg-amber-300 px-1"
                  onClick={() => {
                    setValue("");
                    setFilter("");
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {rows.length === 0 ? (
        <LoadSearch filterInput={value} />
      ) : (
        <>
          <div className="fixed top-[140px] w-full ">
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="bg-slate-300 px-9 py-3 font-bold"
              >
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="mt-[190px] py-3">
            <div {...getTableProps} className="mt-3">
              <div {...getTableBodyProps()}>
                <div className="flex flex-col gap-3">
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps()}
                        className="mx-7 rounded bg-slate-200 p-2"
                      >
                        {row.cells.map((cell) => (
                          <div {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { max } from "date-fns";
import {
  getLatestEarlyArrivalDate,
  getEarliestLateArrivalDate,
  getStartOfExistingLoadsSharedWindow,
  getEndOfExistingLoadsSharedWindow,
} from "modules/common/dateUtilities";

export default function useDate(direction, loads) {
  const [date, setDate] = useState(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (typeof loads === "undefined") {
      setDate(null);
      return;
    }

    if (loads.length === 0) {
      setDate(null);
      queryClient.setQueryData("appointmentOptions", undefined);
      return;
    }

    if (direction === "pickup") {
      if (date === null) {
        setDate(getDefaultDate(loads, direction));
      } else {
        const startOfExistingLoadsSharedWindow =
          getStartOfExistingLoadsSharedWindow(loads);
        const endOfExistingLoadsSharedWindow =
          getEndOfExistingLoadsSharedWindow(loads);
        if (
          date < startOfExistingLoadsSharedWindow ||
          date > endOfExistingLoadsSharedWindow
        ) {
          setDate(getDefaultDate(loads, direction));
        }
        queryClient.removeQueries("appointmentOptions");
      }
    }
    if (direction === "delivery") {
      if (date === null) {
        setDate(getDefaultDate(loads, direction));
      }
    }
  }, [queryClient, loads, direction, date]);

  return { date, setDate };
}

function getDefaultDate(loads, direction) {
  if (typeof loads === "undefined") return undefined;
  if (loads.length === 0) return undefined;

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const earliestNonSameDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1
  );
  const latestEarlyArrivalDate = getLatestEarlyArrivalDate(loads);
  const earliestLateArrivalDate = getEarliestLateArrivalDate(loads);

  if (earliestLateArrivalDate < today) {
    return undefined;
  }

  if (direction === "pickup") {
    return max([latestEarlyArrivalDate, earliestNonSameDay]);
  } else {
    return max([latestEarlyArrivalDate, today]);
  }
}

import ReactModal from "react-modal";
import Card from "../common/containers/Card";
import CardLabel from "../common/labels/CardLabel";
import CardError from "../common/labels/CardError";
import TextInput from "../common/inputs/TextInput";
import { useForm } from "react-hook-form";
import Button from "../common/buttons/Button";
import React from "react";
import useCheckout from "./hooks/useCheckout";
import format from "date-fns/format";

export default function CheckoutModal({
  isOpen,
  appointment,
  closeCheckoutModal,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const { mutate, isLoading } = useCheckout();

  const onSubmit = (data) => {
    data.timestamp = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
    mutate(
      { ...data, uuid: appointment.uuid },
      {
        onSuccess: () => {
          closeCheckoutModal();
        },
      }
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="List of loads"
      style={{
        content: {
          width: 400,
          height: 600,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      {/* <button onClick={closeCheckoutModal}>Close</button> */}
      <form
        className="w-full flex flex-col gap-3 px-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card>
          <CardLabel text="Box Temperature" />
          <TextInput
            name="boxTemp"
            register={register}
            rules={{ required: "Provide box temperature" }}
            autofocus
          />
          <CardError error={errors?.boxTemp?.message} />
        </Card>
        <Card>
          <CardLabel text="Product Temperature" />
          <TextInput
            name="pulpTemp"
            register={register}
            rules={{ required: "Provide product temperature" }}
          />
          <CardError error={errors?.pulpTemp?.message} />
        </Card>
        <Card>
          <CardLabel text="Seal Number" />
          <TextInput
            name="sealNum"
            register={register}
            rules={{ required: "Provide seal number" }}
          />
          <CardError error={errors?.sealNum?.message} />
        </Card>
        <Card>
          <CardLabel text="Comment" />
          <TextInput name="comment" register={register} />
          <CardError error={errors?.name?.message} />
        </Card>
        <div className="flex gap-2">
          <Button className="w-full" submitting={isLoading}>
            Submit
          </Button>
          {!isLoading && (
            <Button
              className="bg-red-500 w-full"
              onClick={() => {
                closeCheckoutModal();
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>
    </ReactModal>
  );
}

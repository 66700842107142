import AppointmentTableLayoutActiveRows from "./AppointmentTableLayoutActiveRows";
import AppointmentTableLayoutCheckedOutRows from "./AppointmentTableLayoutCheckedOutRows";

export default function AppointmentTableLayout({
  getTableProps,
  getTableBodyProps,
  rows,
  prepareRow,
}) {
  return (
    <div {...getTableProps}>
      <div {...getTableBodyProps()} className="flex flex-col">
        <AppointmentTableLayoutActiveRows rows={rows} prepareRow={prepareRow} />
        <AppointmentTableLayoutCheckedOutRows
          rows={rows}
          prepareRow={prepareRow}
        />
      </div>
    </div>
  );
}

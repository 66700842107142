import AppointmentTableLayoutRow from "./AppointmentTableLayoutRow";

export default function AppointmentTableLayoutActiveRows({ rows, prepareRow }) {
  return (
    <div className="mb-10">
      {rows.filter((row) => !row.original.checkout).length > 0 && (
        <div className="flex flex-col gap-3">
          {rows
            .filter((row) => !row.original.checkout)
            .map((row) => {
              prepareRow(row);
              return (
                <AppointmentTableLayoutRow
                  key={row.original.uuid}
                  rows={rows}
                  row={row}
                  isCheckedOut={false}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}

import { useRef, useState } from "react";
import { apiRequest } from "../../services/api";
import AddButton from "../common/buttons/AddButton";
import CardError from "../common/labels/CardError";

export default function EditLoadsModalAdder({
  loads,
  setLoads,
  add,
  setAdd,
  appointment,
  location,
}) {
  const [referenceNumber, setReferenceNumber] = useState("");
  const referenceNumberInput = useRef();
  const [error, setError] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  const isLocationValid = (origin, destination) => {
    if (loads.length === 0) {
      return true;
    }

    if (appointment.direction === "pickup") {
      return loads[0]["origin"] === origin;
    } else if (appointment.direction === "delivery") {
      return loads[0]["destination"] === destination;
    }
  };

  const isUnique = (load_num) => {
    return loads.every((load) => load.load_number !== load_num);
  };

  const validateAndAddLoad = () => {
    if (!referenceNumber) {
      setError("Enter a load, PO or order number");
      return;
    }

    setIsAdding(true);
    apiRequest(
      "get",
      `validate?referenceNumber=${referenceNumber}&location=${location}&direction=${appointment.direction}`
    )
      .then((response) => {
        const {
          load_num,
          origin,
          destination,
          earliest,
          latest,
          po_number,
          order_number,
        } = response;

        if (!isLocationValid(origin, destination)) {
          setError("Location does not match");
          setIsAdding(false);
          return;
        }

        if (!isUnique(load_num)) {
          setError("Load has already been added");
          setIsAdding(false);
          return;
        }

        // if (!isSameAppointment(appointment)) {
        //   setError("loads", {
        //     message: "Load is not part of previously added loads' appointment",
        //   });
        //   setIsAdding(false);
        //   return;
        // }

        // if (!isAfterEarliestDelivery(earliest)) {
        //   setError("loads", {
        //     message: "Load's earliest delivery date is in the future",
        //   });
        //   setIsAdding(false);
        //   return;
        // }

        const updatedLoads = [...loads];
        updatedLoads.push({
          destination,
          earliest,
          latest,
          load_number: load_num,
          order_number,
          origin,
          po_number,
        });
        setLoads(updatedLoads);

        const updatedAdd = [...add];
        updatedAdd.push({
          destination,
          earliest,
          latest,
          load_number: load_num,
          order_number,
          origin,
          po_number,
        });
        setAdd(updatedAdd);

        setReferenceNumber("");
        referenceNumberInput.current.focus();
        setIsAdding(false);
        setError("");
      })
      .catch((error) => {
        setError(error.response.data);
        setIsAdding(false);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-3">
        <input
          className=" w-full p-1 rounded"
          value={referenceNumber}
          ref={referenceNumberInput}
          placeholder="Load, PO or Order Number"
          onChange={(e) => {
            setReferenceNumber(e.target.value);
          }}
        />
        <AddButton onClick={validateAndAddLoad} isAdding={isAdding} />
      </div>
      <CardError error={error} />
    </div>
  );
}

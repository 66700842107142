import { useMemo } from "react";
import { useFlexLayout, useGlobalFilter, useTable } from "react-table";
import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { locations } from "../../../constants/locations";

export default function useCreateScheduledAppointmentsTable(
  scheduledAppointments,
  openDeleteModal,
  openEditLoadsModal,
  openAppointmentTimeModal
) {
  const data = useMemo(() => scheduledAppointments, [scheduledAppointments]);
  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: (row) =>
          formatInTimeZone(
            parseISO(row.appointment_time),
            "UTC",
            "M/d/yy h:mm a"
          ),
        Cell: (cell) => (
          <button
            className="w-4/5 rounded bg-amber-300"
            onClick={() => {
              openAppointmentTimeModal(cell.row.original);
            }}
          >
            {cell.value}
          </button>
        ),
        width: 100,
      },
      { Header: "Appt #", accessor: "appointment_id_3g", width: 75 },
      {
        id: "load_number",
        Header: "Load Number",
        accessor: (row) => {
          return row.loads.map((load) => load.load_number);
        },
        Cell: ({ value }) => {
          return (
            <div className="flex flex-col rounded-l bg-slate-300">
              {value.map((load_number, index) => (
                <div key={index}>{load_number}</div>
              ))}
            </div>
          );
        },
        width: 100,
      },
      {
        id: "po_order_number",
        Header: "PO/Order",
        accessor: (row) => {
          return row.loads.map((load) => {
            if (row.direction === "pickup") {
              return load.po_number ? load.po_number : "--";
            } else if (row.direction === "delivery") {
              return load.order_number ? load.order_number : "--";
            }
            return "--";
          });
        },
        Cell: ({ value }) => {
          return (
            <div className="flex flex-col bg-slate-300">
              {value.map((po_number, index) => (
                <div key={index}>{po_number}</div>
              ))}
            </div>
          );
        },
        width: 100,
      },
      {
        id: "edit",
        Cell: (cell) => {
          if (cell.row.original.checkout) {
            return "";
          } else {
            return (
              <button
                className="w-4/5 rounded-r bg-amber-300"
                onClick={() => {
                  openEditLoadsModal(cell.row.original);
                }}
              >
                Edit
              </button>
            );
          }
        },
        width: 50,
      },
      {
        Header: "Direction",
        accessor: (row) =>
          row.direction === "delivery" ? "Delivery" : "Pickup",
        width: 75,
      },
      {
        Header: "Loc",
        accessor: (row) =>
          locations.northbrook.all.includes(row.location) ? "NB" : "SC",
        width: 50,
      },
      { Header: "Broker", accessor: "broker", width: 100 },
      { Header: "Scheduler", accessor: "scheduler", width: 100 },
      { Header: "Phone", accessor: "phone", width: 75 },
      { Header: "Email", accessor: (row) => row.email.toLowerCase() },
      {
        accessor: "uuid",
        Header: "",
        Cell: (cell) => (
          <div className="flex justify-end">
            <button
              className="w-4/5 rounded bg-amber-300"
              onClick={() => openDeleteModal(cell.value)}
            >
              Delete
            </button>
          </div>
        ),
        width: 75,
      },
    ],
    [openDeleteModal]
  );

  return useTable({ columns, data }, useFlexLayout, useGlobalFilter);
}

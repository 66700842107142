import { useMutation, useQueryClient } from "react-query";
import { apiRequest } from "services/api";

export default function useUpdateAppointmentLoads() {
  const queryClient = useQueryClient();

  return useMutation(({ uuid, timestamp, add, remove }) => {
    apiRequest("put", `update-appointment-loads/${uuid}`, {
      timestamp,
      add,
      remove,
    });
  });
}

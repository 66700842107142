import React from "react";
import AddButton from "../../../../components/common/buttons/AddButton";
import CardError from "../../../../components/common/labels/CardError";
import { apiRequest } from "../../../../services/api";
import LoadValidator from "modules/common/LoadValidator/LoadValidator";
import RemoveButton from "components/common/buttons/RemoveButton";

export default function LoadsList({
  loads,
  append,
  remove,
  setError,
  clearErrors,
  error,
  direction,
  location,
}) {
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const referenceNumberInput = React.useRef();

  const [isAdding, setIsAdding] = React.useState(false);

  const validateAndAddLoad = (e) => {
    e.preventDefault();
    clearErrors("loads");
    if (!location) {
      setError("loads", {
        message: "Choose a location",
      });
    } else if (!direction) {
      setError("loads", {
        message: "Choose a direction",
      });
    } else if (!referenceNumber) {
      setError("loads", {
        message: "Enter a load or PO number",
      });
    } else {
      setIsAdding(true);
      apiRequest(
        "get",
        `validate-scheduler?referenceNumber=${referenceNumber}&location=${location}&direction=${direction}`
      )
        .then((response) => {
          const {
            load_num,
            origin,
            destination,
            earliest,
            latest,
            appointment,
            po_number,
            order_number,
          } = response;

          console.log(response);
          console.log(loads);

          try {
            new LoadValidator(
              loads,
              load_num,
              origin,
              destination,
              earliest,
              latest,
              appointment,
              po_number,
              order_number,
              direction
            ).validate();
          } catch (error) {
            setError("loads", {
              message: error.message,
            });
            setIsAdding(false);
            return;
          }

          append({
            load: load_num,
            origin,
            destination,
            earliest,
            latest,
            appointment,
            po_number,
            order_number,
          });
          setReferenceNumber("");
          referenceNumberInput.current.focus();
          setIsAdding(false);
          clearErrors("confirm");
        })
        .catch((error) => {
          setError("loads", {
            message: error.response.data,
          });
          setIsAdding(false);
        });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <label className="text-base font-medium uppercase">Loads</label>

      {loads.length > 0 ? (
        <LoadsTable loads={loads} remove={remove} />
      ) : (
        <p className="p-1 opacity-40">No Loads Added</p>
      )}
      <div className="flex flex-col ">
        <div className="flex flex-col gap-3">
          <input
            className=" w-full rounded p-1"
            value={referenceNumber}
            ref={referenceNumberInput}
            placeholder="Load, PO or Order Number"
            onChange={(e) => {
              setReferenceNumber(e.target.value);
              clearErrors("loads");
            }}
          />
          <AddButton onClick={validateAndAddLoad} isAdding={isAdding} />
        </div>
        <CardError error={error} />
      </div>
    </div>
  );
}

function LoadsTable({ loads, remove }) {
  return (
    <table>
      <thead className="text-left text-sm">
        <tr>
          <th>Load Number</th>
          <th>Origin/Destination</th>
          <th>PO/Order Number</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="text-sm">
        {loads.map((load, index) => (
          <tr key={load.id}>
            <td>{load.load}</td>
            <td>
              {load.origin} to {load.destination}
            </td>
            <td>{load.po_number || load.order_number || ""}</td>
            <td>
              <RemoveButton
                onClick={(e) => {
                  e.preventDefault();
                  remove(index);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

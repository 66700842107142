import ReactModal from "react-modal";
import Card from "components/common/containers/Card";
import Button from "components/common/buttons/Button";
import React from "react";
import { useState } from "react";
import EditLoadsModalLoads from "./components/EditLoadsModalLoads";
import EditLoadsModalAdder from "./components/EditLoadsModalAdder";
import useUpdateAppointmentLoads from "modules/Admin/components/ScheduleAppointmentsTable/components/EditLoadsModal/hooks/useUpdateAppointmentLoads";
import { format } from "date-fns";
import { useQueryClient } from "react-query";

export default function EditLoadsModal({
  isOpen,
  appointment,
  closeEditLoadsModal,
  location,
}) {
  const queryClient = useQueryClient();

  const [loads, setLoads] = useState(appointment.loads);

  const [remove, setRemove] = useState([]);
  const [add, setAdd] = useState([]);

  const mutation = useUpdateAppointmentLoads();

  const onSubmit = async () => {
    mutation.mutate(
      {
        uuid: appointment.uuid,
        timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        add,
        remove,
        loads,
      },
      {
        onSuccess: () => {
          queryClient.setQueryData("scheduledAppointments", (oldData) => {
            const appointmentKey = oldData.findIndex(
              (apptIterator) => apptIterator.uuid === appointment.uuid
            );
            const updatedData = JSON.parse(JSON.stringify(oldData));
            updatedData[appointmentKey].loads = loads;
            return updatedData;
          });
          closeEditLoadsModal();
        },
      }
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="List of loads"
      style={{
        content: {
          width: 750,
          height: loads.length * 38 + 260,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <Card>
        <EditLoadsModalLoads
          loads={loads}
          setLoads={setLoads}
          setRemove={setRemove}
          remove={remove}
          direction={appointment.direction}
        />
        <EditLoadsModalAdder
          loads={loads}
          setLoads={setLoads}
          add={add}
          setAdd={setAdd}
          appointment={appointment}
          location={location}
        />
      </Card>
      <div className="flex gap-2 mt-2">
        <Button className="w-full" onClick={onSubmit}>
          Submit
        </Button>
        <Button
          className="bg-red-500 w-full"
          onClick={() => {
            closeEditLoadsModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </ReactModal>
  );
}

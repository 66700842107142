import { useState } from "react";

export default function useScheduledAppointmentsTableFilter(
  setGlobalFilter,
  state
) {
  const [value, setValue] = useState(state.globalFilter || "");
  const setFilter = (value) => {
    setGlobalFilter(value || undefined);
  };

  return { value, setValue, setFilter };
}

import CardLabel from "components/common/labels/CardLabel";
import DatePicker from "modules/Scheduler/components/DatePicker";

export default function DateSelection({ direction, setDate, date, loads }) {
  if (!loads || loads.length === 0 || !direction || date === null) {
    return (
      <div className="flex rounded bg-slate-200 p-4 opacity-40">
        <div className="flex w-full flex-col ">
          <CardLabel text="Appointment Date" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <DatePicker
        setDate={setDate}
        date={date}
        loads={loads}
        direction={direction}
      />
    </div>
  );
}
